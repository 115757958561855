import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

const StyledErrorMessage = styled.div`
  width: 100%;
  min-height: 48px;
  margin: 0 auto;
  padding: 12px 14px 12px 16px;
  border-radius: 4px;
  background-color: rgba(255, 48, 69, 0.05);
  font-size: 16px;
  font-weight: 400;
  .error-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    width: 100%;
    padding: 0px 50px;

    @media (max-width: 768px) {
      padding: 0px 10px;
    }
    margin: 0 auto;
    color: ${props => props.theme.smartDark};
  }
`;

type Props = {
  resetError: (bool: boolean) => void;
  message: string;
};

const ErrorMessage = ({ resetError, message }: Props) => {
  return (
    <StyledErrorMessage>
      <div className='error-container'>
        <span>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size={'1x'}
            color={'#ef4444'}
            style={{ marginRight: '13px' }}
          />
          {message}
        </span>
        <FontAwesomeIcon
          onClick={() => resetError(false)}
          icon={faTimes}
          size={'1x'}
          cursor={'pointer'}
          color={'#36373c'}
        />
      </div>
    </StyledErrorMessage>
  );
};

export default ErrorMessage;
