import React from 'react';
import styled from 'styled-components/macro';

import useCustomerDetails from '../../hooks/api/useCustomerDetails';
import useStatementDetails from '../../hooks/api/useStatementDetails';

import { isOverDue } from '../../utils/isOverdue';
import { overduePrice } from '../../utils/overduePrice';
import { StyledStatus } from '../Billing/Status';

// Components
import { PrimaryButtonLink } from '../Buttons';
import Card from '../Card/Card';
import SkeletonCard from '../Card/SkeletonCard';

const StyledBalanceContainer = styled.div`
  margin-top: -30px;
  text-align: left;
  h3 {
    margin-top: 0px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;

const AccountBalanceCard = () => {
  const {
    data: statementData,
    isError: isStatementError,
    isLoading: isStatementLoading,
    isIdle: isStatementIdle
  } = useStatementDetails();

  const {
    data: customerData,
    isError: isCustomerError,
    isLoading: isCustomerLoading,
    isIdle: isCustomerIdle
  } = useCustomerDetails();

  if (isStatementIdle || isStatementLoading || isCustomerIdle || isCustomerLoading)
    return <SkeletonCard count={7} />;

  if (isStatementError || isCustomerError)
    return (
      <Card title='Information unavailable' isError={isStatementError || isStatementError}>
        Oops, something went wrong. Please refresh the page.
      </Card>
    );
  if (!statementData || !customerData)
    return (
      <Card title='Information unavailable' isError={isStatementError || isStatementError}>
        You do not have any statements or customer details.
      </Card>
    );
  const currentBalance =
    customerData!.currentBalance < 0
      ? customerData!.currentBalance.toFixed(2).replace('-', '') + ' cr \u2665	'
      : customerData!.currentBalance.toFixed(2);
  return (
    <Card title='Account Balance'>
      <StyledBalanceContainer>
        <div className='detail'>
          <p>Total Outstanding</p>
          <h3 style={{ color: customerData!.currentBalance <= 0 ? '#34d399' : '' }}>
            ${currentBalance}
          </h3>
        </div>

        <div className='detail'>
          <p>Total Overdue</p>
          <h3>
            {overduePrice(statementData!.currentCharges, customerData!.currentBalance)}

            <StyledStatus
              overdue={isOverDue(statementData!.currentCharges, customerData!.currentBalance)}
            >
              {isOverDue(statementData!.currentCharges, customerData!.currentBalance)
                ? 'Overdue'
                : 'Nothing overdue'}
            </StyledStatus>
          </h3>
        </div>
      </StyledBalanceContainer>

      <div style={{ alignSelf: 'center' }}>
        <PrimaryButtonLink to='/pay'>Make a payment</PrimaryButtonLink>
      </div>
    </Card>
  );
};

export default AccountBalanceCard;
