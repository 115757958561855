import React from 'react';
import styled from 'styled-components/macro';
import ErrorMessage from '../ErrorMessage';
import AddressHeader from './AddressHeader';
import Footer from './Footer';
import Header from './Header';
import { Helmet } from 'react-helmet-async';
const LayoutContainer = styled.div<{ mainPadding?: string }>`
  margin: 24px auto;

  max-width: 826px;
  width: 90%;
  .main {
    padding-top: ${props => props.mainPadding || '56px'};
    @media (max-width: 1024px) {
      padding-top: 10px;
    }
  }
`;

const LayoutWrapper = styled.div<{ background: string }>`
  background: ${props => props.background};
  width: 100%;
  min-height: 100vh;
`;

type Props = {
  resetError?: (state: boolean) => void;
  showError?: boolean;
  pageTitle: string;
  addressHeader?: boolean;
  background?: string;
  mainPadding?: string;
};

const Layout: React.FC<Props> = ({
  children,
  showError,
  pageTitle,
  resetError,
  background = '#fff',
  addressHeader = false,
  mainPadding = '56px'
}) => {
  return (
    <LayoutWrapper background={background}>
      <Helmet>
        <title>Smart Energy | {pageTitle}</title>
      </Helmet>
      <Header />
      {addressHeader ? <AddressHeader /> : null}
      {showError && resetError ? (
        <ErrorMessage resetError={resetError} message='An error occurred' />
      ) : null}
      <LayoutContainer mainPadding={mainPadding}>
        <div className='main'>{children}</div>
      </LayoutContainer>
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
