import styled from 'styled-components/macro';

import DesktopNav from '../Navigation/DesktopNav';
import MobileNav from '../Navigation/MobileNav';
import { auth, logout } from '../../service/firebase';
import { useHistory } from 'react-router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import newHeaderBg from '../../images/new-header-bg.png';
import logo from '../../images/logo.svg';

import LoginLogoutButton from '../Navigation/LoginLogoutButton';
import { type } from 'os';
import { LocationModel } from '../Helpers/Location';

const StyledHeaderContainerMobile = styled.div`
  @media print {
    display: none;
  }

  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: solid 1px ${props => props.theme.borderColor};
  @media only screen and (min-width: 1024px) {
    display: none;
  }
  .mobile-logo {
    margin-bottom: 0;
    background-image: none;
    padding: 10px 30px;
  }
`;

const StyledHeaderContainerDesktop = styled.div`
  display: none;
  @media print {
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 54px;
    width: 100%;
    background: #fff;
    transition: margin 0.2s;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  }

  &:before {
    @media (min-width: 1354px) {
      content: '';

      position: absolute;
      left: 0;
      top: 43px;
      z-index: 3;

      width: 272px;
      height: 100%;

      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    }
    @media only screen and (min-width: 1750px) {
      width: calc((100vw - 1231px) / 2);
    }
    @media only screen and (min-width: 2000px) {
      width: calc((100vw - 1231px) / 2);
    }

    @media (min-width: 1400px) {
      width: calc((100vw - 1229px) / 2);
    }
  }

  a {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
  }
  .navbar {
    position: relative;
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    &-brand {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      padding: 0.575rem 5rem 2.1rem 70px;
      background: url(${newHeaderBg}) no-repeat;
      background-position: right bottom;
      background-size: cover;

      @media (min-width: 1355px) {
        padding-left: 0;
        left: calc((100vw - 1231px) / 2);
      }
      @media (min-width: 1920px) {
        padding-left: 0;
        left: 344px;
      }
    }
  }
`;

type Props = {
  getLocation?: LocationModel;
};

const Header = ({getLocation} : Props) => {
  const history = useHistory();
  const { clearUser } = useContext(UserContext);
  const [user] = useAuthState(auth);
  const logoutHandler = () => {
    clearUser();
    logout();
    history.replace('/');
  };

  return (
    <>
      <StyledHeaderContainerDesktop>
        <div className='navbar'>
          <a href={!getLocation?.hideLogin ? 'https://smartenergy.co/au/' : 'https://uk.smartenergy.co'} className='navbar-brand'>
            <img src={logo} alt='Smart Energy' />
          </a>
          {user && <DesktopNav />}

          {
            !getLocation?.hideLogin ? <LoginLogoutButton user={user} logoutHandler={logoutHandler} /> : ''
          }       
        </div>
      </StyledHeaderContainerDesktop>
      <StyledHeaderContainerMobile>
        <a href={!getLocation?.hideLogin ? 'https://smartenergy.co/au/' : 'https://uk.smartenergy.co'}>
          <img src={logo} alt='Smart Energy' className='mobile-logo' />
        </a>

        <LoginLogoutButton user={user} logoutHandler={logoutHandler} />
      </StyledHeaderContainerMobile>
      {user && <MobileNav />}
    </>
  );
};

export default Header;
