import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import Header from '../components/Layout/Header';
import { StripeOrderDetails } from '../types/StripePayment';

const StyledHeader = styled.div`
  padding: 54px 0px 0px 0px;
  text-align: center;
  margin-top: 54px;
  .success-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: ${props => props.theme.smartGreen};
    margin: 0 auto;
  }
  .fail-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: ${props => props.theme.smartRed};
    margin: 0 auto;
  }
`;

const StyledContainer = styled.div`
  max-width: 90%;
  width: 405px;
  margin: 32px auto 0px auto;
  .buttons {
    margin-top: 31px;
    display: flex;
    justify-content: space-between;
  }
`;

const StyledPaymentDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: solid 1px ${props => props.theme.borderColor};
  span {
    font-weight: 900;
  }
  p {
  }
`;

const StripeSuccessScreen = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [orderData, setOrderData] = useState<StripeOrderDetails>();
  const stripeOrder: { salesOrderNo: string; sessionId: string } = JSON.parse(
    window.localStorage.getItem('stripeOrder')!
  );
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setIsSuccess(true);
    }

    if (query.get('canceled')) {
      setIsSuccess(false);
    }
  }, []);

  useEffect(() => {
    if (!orderData) {
      const fetchStripeOrderDetails = async () => {
        try {
          const { data } = await axios.get<any, AxiosResponse<StripeOrderDetails>>(
            `${process.env.REACT_APP_API}/Payment/StripeOrderDetails`,
            {
              headers: {
                sessionId: stripeOrder.sessionId
              }
            }
          );

          setOrderData(data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchStripeOrderDetails();
    }
  }, [orderData]);

  useEffect(() => {
    return () => window.localStorage.removeItem('stripeOrder');
  }, []);

  return (
    <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      <Header />
      <StyledHeader>
        {isSuccess ? (
          <div className='success-icon'>
            <FontAwesomeIcon icon={faCheck} color='#fff' />
          </div>
        ) : (
          <div className='fail-icon'>
            <FontAwesomeIcon icon={faExclamationCircle} color='#fff' />
          </div>
        )}
        {isSuccess ? <h1>Payment completed</h1> : <h1>Payment could not be completed</h1>}
        {isSuccess ? (
          <p>Your payment was accepted and receipt has been sent to your email.</p>
        ) : (
          <p>Payment was canceled.</p>
        )}
      </StyledHeader>
      {orderData && isSuccess ? (
        <StyledContainer>
          <StyledPaymentDetail>
            <span>Email sent to</span>
            <p>{orderData?.email}</p>
          </StyledPaymentDetail>
          <StyledPaymentDetail>
            <span>Sales order number</span>
            <p>{stripeOrder.salesOrderNo}</p>
          </StyledPaymentDetail>
          <StyledPaymentDetail>
            <span>Payment type</span>
            <p>Card ending {orderData?.maskedCard}</p>
          </StyledPaymentDetail>
          <StyledPaymentDetail>
            <span>Customer reference</span>
            <p>{orderData?.customerId}</p>
          </StyledPaymentDetail>
          <StyledPaymentDetail>
            <span>Payment reference</span>
            <p>{orderData?.paymentIntentId}</p>
          </StyledPaymentDetail>
          <StyledPaymentDetail>
            <span>Payment amount</span>
            <p>
              {'$'}
              {orderData?.amountReceived ? (orderData.amountReceived / 100).toFixed(2) : 'blank'}
            </p>
          </StyledPaymentDetail>
        </StyledContainer>
      ) : (
        false
      )}
    </div>
  );
};

export default StripeSuccessScreen;
