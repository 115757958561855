import React, { useContext, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { db, auth, logout } from '../service/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { CustomerAuth } from '../types/CustomerAuth';
import { ApiParamsAndHeaders } from '../types/ApiParamsAndHeaders';
import axios from 'axios';
import { getCustomerDetails } from '../hooks/api/useCustomerDetails';

export type AvailableCustomerNumber = { customerNumber: string; displayValue: string };

class LoggedInUser {
  constructor(
    public customerNumber: string = '',
    public token: string = '',
    public availableCustomerNumbers: AvailableCustomerNumber[] = []
  ) {}
}

export const UserContext = React.createContext({
  loggedInUser: new LoggedInUser(),
  apiParamsAndHeaders: {} as ApiParamsAndHeaders,
  clearUser: () => {},
  handleSwitchCustomerNumber: (customerNumber: string) => {}
});

const UserProvider: React.FC = ({ children }) => {
  const [user] = useAuthState(auth);
  const [loggedInUser, setIsChanged] = useLocalStorage('customerNumber', new LoggedInUser());

  const clearUser = () => {
    logout();
    setIsChanged(new LoggedInUser());
  };

  const handleSwitchCustomerNumber = (customerNumber: string) => {
    const newLoggedInUser = { ...loggedInUser };
    newLoggedInUser.customerNumber = customerNumber;
    setIsChanged(newLoggedInUser);
  };

  const createParams = (customerNumber: string, token: string = loggedInUser.token) => {
    return {
      apiParams: {
        apiParams: [
          {
            key: 'customerNumber',
            value: customerNumber
          }
        ]
      },
      headers: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    };
  };

  const addCustomerNumbers = (customerNumbers: AvailableCustomerNumber[], customerDetails: any) => {
    const customerNumber = {
      customerNumber: customerDetails.customerNumber,
      displayValue: `${customerDetails.customerNumber}: ${customerDetails.homeAddress}, ${customerDetails.homeSuburb} ${customerDetails.homeState} ${customerDetails.homePostalCode}`
    };

    const customerExists = customerNumbers.filter(
      customer => customer.customerNumber === customerNumber.customerNumber
    ).length;

    if (customerExists) {
      return customerNumbers;
    } else {
      return [...customerNumbers, customerNumber];
    }
  };

  useEffect(() => {
    if (user) {
      user.getIdToken().then(token =>
        db
          .collection('customers')
          .doc(user.uid)
          .get()
          .then(snapshot => {
            const customerNumbers = snapshot.data()?.customerNumber.split(';') as string[];

            const customerDetails = customerNumbers.map(async customerNumber => {
              return await getCustomerDetails(createParams(customerNumber, token));
            });

            Promise.all(customerDetails)
              .then(customerDetails => {
                const availableCustomerNumbers = customerDetails.reduce(addCustomerNumbers, []);

                setIsChanged(new LoggedInUser(customerNumbers[0], token, availableCustomerNumbers));
              })
              .catch(logError);
          })
          .catch(logError)
      );
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        loggedInUser,
        clearUser,
        apiParamsAndHeaders: createParams(loggedInUser.customerNumber, loggedInUser.token),
        handleSwitchCustomerNumber
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
const logError = (error: Error) => console.error(error);

export default UserProvider;
