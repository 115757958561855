import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import { ApiParamsAndHeaders } from '../../types/ApiParamsAndHeaders';
import { useQueryConfig } from '../useQueryConfig';

const getAccountNumber = async (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const { data } = await axios.post<any, AxiosResponse<boolean>>(
    `${process.env.REACT_APP_API}/Account/Number`,
    { ...apiParamsAndHeaders.apiParams },
    { ...apiParamsAndHeaders.headers }
  );
  return data;
};

export default function useAccountNumber() {
  const { apiParamsAndHeaders } = useContext(UserContext);
  return useQuery<boolean, Error>(
    ['accountExists', apiParamsAndHeaders],
    () => getAccountNumber(apiParamsAndHeaders),
    useQueryConfig(apiParamsAndHeaders)
  );
}
