import axios, { AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import DownloadLink from '../DownloadLink';
import { Line } from '../Line';
import ClipLoader from 'react-spinners/ClipLoader';
import { convertDate } from '../../utils/convertDate';
import { UserContext } from '../../context/UserContext';

const StyledBill = styled.div<{ status: 'due' | 'overdue' | 'paid' | '' }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 12px 0px;

  .price {
    font-weight: 600;
  }

  .status {
    display: block;
    background: ${props =>
      props.status === 'paid'
        ? '#27c486'
        : props.status === 'overdue'
        ? '#ff3045'
        : props.status === 'due'
        ? ' #f80'
        : 'none'};
    height: 24px;
    padding: 0px 8px;
    font-weight: 600;
    text-align: center;
    border-radius: 100px;
    line-height: 24px;
    color: #fff;
    font-size: 12px;
    margin-left: 16px;
  }

  .mobile-download {
    display: block;
    justify-self: flex-end;
  }

  .desktop-download {
    display: none;
  }

  .flex-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    .date {
      width: 100%;
      margin-left: 100px;
    }
    .flex-wrapper {
      width: 160px;
    }
    .mobile-download {
      display: none;
    }
    .desktop-download {
      display: block;
      align-self: center;
      justify-self: flex-end;
    }
  }
`;

type Props = {
  status: 'due' | 'overdue' | 'paid' | '';
  price: string;
  startDate: string;
  endDate: string;
  statementNumber: number;
};

const Bill = ({ status, price, statementNumber, startDate, endDate }: Props) => {
  const [loading, setLoading] = useState(false);
  const { loggedInUser } = useContext(UserContext);

  const getStatementPdf = (statementNumber: number) => {
    if (statementNumber) {
      setLoading(true);
      axios
        .post<any, AxiosResponse<any>>(
          `${process.env.REACT_APP_API}/Statement/Pdf`,
          {
            apiParams: [
              {
                key: 'customerNumber',
                value: loggedInUser.customerNumber
              },
              {
                key: 'statementNumber',
                value: statementNumber.toString()
              }
            ]
          },
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf',
              Authorization: `Bearer ${loggedInUser.token}`
            }
          }
        )
        .then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `statement-${statementNumber}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.style.cursor = 'default';
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <>
      <StyledBill status={status}>
        <div className='flex-wrapper'>
          <div className='flex-wrapper'>
            <p className='price'>{price}</p>
            {/* <span className='status'>{status}</span> */}
          </div>
          <div className='mobile-download'>
            {loading ? (
              <div>
                <ClipLoader color={'#2b5cff'} speedMultiplier={0.5} />
              </div>
            ) : (
              <DownloadLink download={() => getStatementPdf(statementNumber)} />
            )}
          </div>
        </div>
        <p className='date'>{`${convertDate(startDate)} - ${convertDate(endDate)}`}</p>
        <div className='desktop-download'>
          {loading ? (
            <div>
              <ClipLoader color={'#2b5cff'} speedMultiplier={0.5} />
            </div>
          ) : (
            <DownloadLink download={() => getStatementPdf(statementNumber)} />
          )}
        </div>
      </StyledBill>
      <Line />
    </>
  );
};

export default Bill;
