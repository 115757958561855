import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { PrimaryButton } from '../Buttons';
import Input from '../Input';

import bpointLogo from '../../images/bpoint-logo.png';

import SecurePaymentsLogos from './SecurePaymentsLogos';
import PaymentOptions from './PaymentOptions';
import { UserContext } from '../../context/UserContext';

import cardValidator from 'card-validator';
import CardDetailsInputs from './CardDetailsInputs';

import { PaymentPayload } from '../../types/Payment';
import { usePriceState } from '../../hooks/usePriceState';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../service/firebase';
import useCustomerDetails from '../../hooks/api/useCustomerDetails';
import axios, { AxiosError } from 'axios';
import { isExpiryDateValid } from '../../utils/isExpiryDateValid';
import { ClipLoader } from 'react-spinners';

import BPayInfo from './BPayInfo';
import PaymentAmountInput from './PaymentAmountInput';
import { useHistory } from 'react-router';
import SelectAmount from './SelectAmount';

const StyledFieldWrapper = styled.div`
  max-width: 405px;
  width: 90%;
  margin: 0 auto;
`;

type Props = {
  setSubmitError: (isError: boolean) => void;
  setErrorMessage: (message: string) => void;
};
const EnergyPaymentForm = ({ setSubmitError, setErrorMessage }: Props) => {
  const { data, isLoading, isError } = useCustomerDetails();
  const numbersOnly = /\D/;
  const [user] = useAuthState(auth);
  const history = useHistory();
  const { loggedInUser } = useContext(UserContext);
  const [customerNumber, setCustomerNumber] = useState(
    loggedInUser.customerNumber && user ? loggedInUser.customerNumber : ''
  );
  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardName, setCardName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvn, setCvn] = useState('');
  const [paymentAmount, setPaymentAmount] = usePriceState('');
  const [amountError, setAmountError] = useState(false);
  const [customerNoError, setCustomerNoError] = useState(false);
  const [option, setOption] = useState<'credit-card' | 'poli' | 'bpay'>('credit-card');
  const dateError = isExpiryDateValid(month, year);

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const [amountType, setAmountType] = useState<'over' | 'total' | ''>('');

  useEffect(() => {
    if (loggedInUser) {
      setCustomerNumber(loggedInUser.customerNumber);
    }
  }, [loggedInUser]);

  const cvnError =
    cardNumber !== '' && cvn.length < 3
      ? true
      : cardValidator.number(cardNumber).card?.type === 'american-express' && cvn.length < 4
      ? true
      : false;

  const enableButton = () =>
    [customerNumber, cardNumber, paymentAmount, cardName, month, year, cvn].some(
      value => value.trim() === ''
    ) || [amountError, cardNumberError, cvnError, dateError].some(value => value === true);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPaymentLoading(true);
    const payload = new PaymentPayload(
      parseFloat(paymentAmount),
      'AUD',
      'Smart Energy',
      customerNumber,
      user ? (user.email as string) : email,
      {
        cardNumber: cardNumber,
        expiryDate: month + year,
        cvn: cvn,
        cardHolderName: cardName
      }
    );

    axios
      .post(`${process.env.REACT_APP_API}/Payment/BPoint`, payload)
      .then(res => {
        console.debug(res);
        setIsPaymentLoading(false);
        history.replace({
          pathname: '/pay/success',
          state: {
            data: res.data,
            name: cardName,
            paymentAmount: parseFloat(paymentAmount),
            email: user ? (user.email as string) : email
          }
        });
      })
      .catch((err: Error | AxiosError) => {
        setIsPaymentLoading(false);
        if (axios.isAxiosError(err)) {
          // Access to config, request, and response
          setSubmitError(true);

          setErrorMessage(err.message);
          console.debug('custom', err);
        } else {
          // Just a stock error
          console.log('stock error', err);
          setErrorMessage(err.message);
          setSubmitError(true);
          console.debug(err);
        }
      });
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = parseInt(e.target.value);
    if (number <= 12 && !numbersOnly.test(e.target.value)) {
      setMonth(e.target.value.padStart(1, '0'));
    } else {
      setMonth('');
    }
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!numbersOnly.test(e.target.value)) {
      setYear(e.target.value);
    } else {
      setYear('');
    }
  };

  const handleCardNumber = (value: string) => {
    if ((!numbersOnly.test(value) && value.length < 17) || value === '') {
      setCardNumber(value);
      const cardNumber = cardValidator.number(value);

      if (!cardNumber.isValid) {
        setCardNumberError(true);
      } else {
        setCardNumberError(false);
      }
    }
  };

  const handleCardName = (value: string) => {
    if (value.length <= 50) {
      setCardName(value);
    }
  };

  const handleCvn = (value: string) => {
    const cvnLength: number =
      cardValidator.number(cardNumber).card?.type === 'american-express' ? 4 : 3;
    if ((!numbersOnly.test(value) && value.length <= cvnLength) || value === '') {
      setCvn(value);
    }
  };

  const handlePaymentAmount = (value: string) => {
    if (parseInt(value) || value === '') {
      setPaymentAmount(value);
      if (value === '') {
        setAmountError(true);
      } else {
        setAmountError(false);
      }
    }
  };

  const handlePaymentOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value as 'credit-card' | 'poli' | 'bpay');
  };

  const handlePaymentAmountSelect = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    value: string,
    amountType: 'over' | 'total' | ''
  ) => {
    e?.preventDefault();

    if (parseInt(value) || value === '') {
      setAmountType(amountType);

      setPaymentAmount(value);
      if (value === '') {
        setAmountError(true);
      } else {
        setAmountError(false);
      }
    }
  };

  const handleCustomerNumber = (value: string) => {
    if (parseInt(value) || value === '') {
      setCustomerNumber(value);
      if (value === '') {
        setCustomerNoError(true);
      } else {
        setCustomerNoError(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledFieldWrapper>
        <Input
          tip='Your Account Number can be found in the top corner of your energy bill or in the email sent to you.'
          isError={customerNoError}
          disabled={loggedInUser.customerNumber ? true : false}
          label='Account number'
          type='text'
          placeholder='e.g. 700000002'
          value={customerNumber}
          errorMessage='This account number is not recognised, please enter the account number displayed on your energy bill'
          onChange={handleCustomerNumber}
        />
        {!user ? (
          <Input
            isError={false}
            label='Customer email'
            type='email'
            placeholder='e.g. example@email.com'
            value={email}
            onChange={setEmail}
          />
        ) : null}
      </StyledFieldWrapper>

      <PaymentOptions
        handlePaymentOptionChange={handlePaymentOptionChange}
        option={option}
        fees={false}     
      />

      <StyledFieldWrapper>
        {option === 'credit-card' ? (
          <CardDetailsInputs
            cardNumberError={cardNumberError}
            cardNumber={cardNumber}
            handleCardNumber={handleCardNumber}
            cardName={cardName}
            handleCardName={handleCardName}
            month={month}
            handleMonthChange={handleMonthChange}
            year={year}
            handleYearChange={handleYearChange}
            dateError={dateError}
            cvnError={cvnError}
            cvn={cvn}
            handleCvn={handleCvn}
          />
        ) : (
          // ) : option === 'poli' ? (
          //   <PoliInfo />
          <BPayInfo />
        )}
        {option !== 'bpay' && (
          <>
            {loggedInUser.customerNumber ? (
              <SelectAmount
                handlePaymentAmountSelect={handlePaymentAmountSelect}
                amountType={amountType}
              />
            ) : (
              <PaymentAmountInput
                isError={amountError}
                label='Enter amount to pay'
                info={
                  isLoading
                    ? 'loading'
                    : isError
                    ? ''
                    : data
                    ? '$' + data?.currentBalance.toFixed(2)
                    : ''
                }
                errorMessage='Must add amount'
                amount={paymentAmount}
                onChange={handlePaymentAmount}
              />
            )}

            {isPaymentLoading ? (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <ClipLoader color={'#2b5cff'} />
              </div>
            ) : (
              <PrimaryButton style={{ marginTop: '16px' }} disabled={enableButton()}>
                Pay now
              </PrimaryButton>
            )}
          </>
        )}
        {/* <TransactionFees paymentAmount={paymentAmount} percentage={1.78} /> */}

        <SecurePaymentsLogos img1={bpointLogo} />
      </StyledFieldWrapper>
    </form>
  );
};

export default EnergyPaymentForm;
