import React from 'react';
import styled from 'styled-components/macro';
import Navbar from './Navbar';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  min-height: 56px;
  background-color: white;
  border-bottom: solid 1px ${props => props.theme.borderColor};
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

const MobileNav = () => {
  return (
    <Wrapper>
      <Navbar />
    </Wrapper>
  );
};

export default MobileNav;
