export const makeValidDate = (string: string) => {
  // change month/day/year to day/month/year
  let dateArray = string.split('/');
  let day = dateArray[0];
  dateArray[0] = dateArray[1];
  dateArray[1] = day;

  // return will be day/month/year
  return new Date(dateArray.join('/'));
};
