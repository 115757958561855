import axios, { AxiosResponse } from 'axios';

import { useQuery } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import { ApiParamsAndHeaders } from '../../types/ApiParamsAndHeaders';
import { useQueryConfig } from '../useQueryConfig';
import { ServiceDetails } from '../../types/ServiceDetails';

const getServiceDetails = async (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const { data } = await axios.post<any, AxiosResponse<ServiceDetails>>(
    `${process.env.REACT_APP_API}/RatePlan/Service`,
    { ...apiParamsAndHeaders.apiParams },
    { ...apiParamsAndHeaders.headers }
  );
  return data;
};

export default function useService() {
  const { apiParamsAndHeaders } = useContext(UserContext);

  return useQuery<ServiceDetails, Error>(
    ['serviceDetails', apiParamsAndHeaders],
    () => getServiceDetails(apiParamsAndHeaders),
    useQueryConfig(apiParamsAndHeaders)
  );
}
