import React from 'react';
import styled from 'styled-components/macro';
import useAccountNumber from '../../hooks/api/useAccountNumber';
// Components
import Card from '../Card/Card';
import SkeletonCard from '../Card/SkeletonCard';
// Assets
import directDebitBg from '../../images/direct-debit-bg.svg';

const HasDirectDebitCard = styled.div`
  display: flex;
  display: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  background-image: url(${directDebitBg});
  background-size: cover;
  background-position: center;
  padding: 2em 2em 0 2em;
  margin: 11px auto;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${props => props.theme.borderColor};
  height: 226px;
  h2 {
    font-size: 20px;
    margin: 0;
  }
  p {
    font-weight: 700;
    margin-bottom: 24px;
    span {
      font-weight: 500;
    }
  }
  @media only screen and (min-width: 768px) {
    margin: 0px 11px 22px 11px;
    width: 402px;
  }
`;

const DirectDebitCard = () => {
  const { data: accountNumber, isLoading, isError, isIdle } = useAccountNumber();

  if (isLoading || isIdle) return <SkeletonCard />;

  if (accountNumber)
    return (
      <HasDirectDebitCard>
        <h2>Payment Method</h2>
        <p>
          <span>Direct Debit</span> {accountNumber}
        </p>
      </HasDirectDebitCard>
    );

  if (isError)
    return (
      <Card title='Information unavailable' isError={isError}>
        Oops, something went wrong. Please refresh the page.
      </Card>
    );

  return (
    <Card
      textAlign='left'
      imgSrc={directDebitBg}
      button='primary'
      title='Payment method'
      linkUrl='https://smartenergy.co/au/help-support/'
      externalLink={true}
      buttonText='Contact help and support'
    >
      <h3>Set up direct debit</h3>
      <p>Please contact support on 1300 133 055 to setup Direct Debit</p>
    </Card>
  );
};

export default DirectDebitCard;
