import { useHistory } from 'react-router';
import { ApiParamsAndHeaders } from '../types/ApiParamsAndHeaders';

export const useQueryConfig = (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const history = useHistory();
  const config = {
    enabled: !!apiParamsAndHeaders.headers.headers.Authorization,
    onError: (err: Error) => {
      if (err.message.includes('401'))
        history.replace('/unauthorised', { error: { message: err.message } });
    }
  };
  return config;
};
