import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components/macro';
import { LocationModel } from '../Helpers/Location';
import ToolTip from '../ToolTip';

type Props = {
  amount: string;
  label: string;
  isError?: boolean;
  disabled?: boolean;
  onChange: (e: string) => void;
  errorMessage?: string;
  tip?: string;
  info?: string | number;
  getLocation? : LocationModel;
};

const StyledInputWrapper = styled.div<{ isError: boolean }>`
  input {
    border: solid 1px ${props => (props.isError ? props.theme.smartRed : props.theme.inputBorder)};
  }
`;
const StyledCurrencyInput = styled(CurrencyInput)`
  background: #fff;
  border-radius: 4px;
  padding-left: 10px;
  margin-top: 16px;
  font-size: 16px;
  height: 46px;
  width: 100%;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const PaymentAmountInput = ({
  label,
  amount,
  disabled = false,
  onChange,
  isError = false,
  errorMessage,
  tip = '',
  info = '',
  getLocation
}: Props) => {
  return (
    <label>
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 600,
          marginTop: '16px',
          right: '0px'
        }}
      >
        {label}
        {tip ? <ToolTip tip={tip} /> : null}
        {info ? <span>{info}</span> : null}
      </span>
      <StyledInputWrapper isError={isError}>
        <StyledCurrencyInput
          id='input-example'
          name='input-name'
          value={amount}
          disabled={disabled}
          placeholder= {getLocation?.currencySign.concat('0.00' )}
          decimalsLimit={2}
          onValueChange={value => onChange(value ? value : '')}
          allowNegativeValue={false}
          prefix={getLocation?.currencySign}
        />
      </StyledInputWrapper>
      {isError && (
        <span
          style={{
            display: 'block',
            fontWeight: 600,
            color: '#ef4444',
            marginTop: '8px',
            marginBottom: '16px'
          }}
        >
          {errorMessage}
        </span>
      )}
    </label>
  );
};
export default PaymentAmountInput;
