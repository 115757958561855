import React from 'react';
import styled from 'styled-components/macro';

export const StyledLoginContainer = styled.div`
  max-width: 402px;
  margin: 0px auto 0px auto;
  @media (min-width: 1024px) {
    margin: 100px auto 0px auto;
  }
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 32px;
    white-space: pre-wrap;
  }
  .forgot-link {
    display: block;
    text-align: center;
    margin: 24px auto;
    font-weight: 600;
    color: ${props => props.theme.smartDark};
  }
  form {
    label {
      display: block;
      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
    img {
      display: block;
      margin: 0px auto 20px auto;
      height: auto;
      width: 200px;
    }
  }
`;

type Props = {
  title: string;
  description?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};
const LoginForm: React.FC<Props> = ({ title, children, description, onSubmit }) => {
  return (
    <StyledLoginContainer>
      <h1>{title}</h1>
      {description ? <p>{description}</p> : null}
      <form onSubmit={onSubmit}>{children}</form>
    </StyledLoginContainer>
  );
};

export default LoginForm;
