import styled from 'styled-components/macro';

export const StyledStatus = styled.span<{ overdue: boolean }>`
  display: inline-block;
  background: ${props => (props.overdue ? '#ff3045' : '#f7f7f7')};
  height: 24px;
  padding: 0px 8px;
  font-weight: 600;
  text-align: center;
  border-radius: 100px;
  line-height: 24px;
  color: ${props => (props.overdue ? '#fff' : '#000')};
  font-size: 12px;
  margin-left: 16px;
`;
