import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components/macro';
import useCustomerDetails from '../../hooks/api/useCustomerDetails';
import useStatementDetails from '../../hooks/api/useStatementDetails';
import { isOverDue } from '../../utils/isOverdue';
import { overduePrice } from '../../utils/overduePrice';
import DropDownArrow from '../Icons/DropDownArrow';

const AmountButton = styled.button<{ active?: boolean }>`
  padding: 12px 20px;
  width: 100%;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: left;
  background: none;
  display: block;
  border-radius: 2px;
  &:hover {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  }
  border: solid 1px ${props => (props.active ? props.theme.smartBlue : '#dadada')};
  opacity: ${props => (props.disabled ? '50%' : '100%')};
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  span {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .price {
    font-weight: 900;
  }
`;

const StyledSelectAmount = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin: 16px 0px;
    font-size: 16px;
    font-weight: 900;
  }
`;

const SelectAmountDropdown = styled.div`
  display: flex;
  width: 100%;
`;
type Props = {
  amountType: 'total' | 'over' | '';
  handlePaymentAmountSelect: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    value: string,
    amountType: 'total' | 'over' | ''
  ) => void;
};

const SelectAmount = ({ handlePaymentAmountSelect, amountType }: Props) => {
  const {
    data: statementData,
    isError: isStatementError,
    isLoading: isStatementLoading,
    isIdle: isStatementIdle
  } = useStatementDetails();

  const {
    data: customerData,
    isError: isCustomerError,
    isLoading: isCustomerLoading,
    isIdle: isCustomerIdle
  } = useCustomerDetails();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (customerData?.currentBalance) {
      handlePaymentAmountSelect(null, customerData.currentBalance.toFixed(2), 'total');
    }
  }, [customerData]);

  if (isStatementLoading || isStatementIdle || isCustomerLoading || isCustomerIdle) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <ClipLoader color={'#2b5cff'} />
      </div>
    );
  }

  if (isStatementError || isCustomerError) {
    return <p>Oops, something went wrong. Please refresh the page.</p>;
  }
  if (!customerData || !statementData) {
    return <p>no balances to display</p>;
  }
  const openAmountsHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <StyledSelectAmount style={{}}>
      <label>Payment amount</label>
      {!isOverDue(statementData!.currentCharges, customerData!.currentBalance) ? (
        <AmountButton
          disabled={customerData!.currentBalance <= 0}
          active={amountType === 'total'}
          onClick={e =>
            handlePaymentAmountSelect(e, customerData!.currentBalance.toFixed(2), 'total')
          }
        >
          <span className='price'>{'$' + customerData!.currentBalance.toFixed(2)}</span>
          <span>Total outstanding</span>
        </AmountButton>
      ) : (
        <>
          <AmountButton
            disabled={
              customerData!.currentBalance <= 0 &&
              !isOverDue(statementData!.currentCharges, customerData!.currentBalance)
            }
            onClick={openAmountsHandler}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {amountType === 'over' ? (
                <div>
                  <span className='price'>
                    {overduePrice(statementData!.currentCharges, customerData!.currentBalance)}
                  </span>
                  <span>Overdue amount</span>
                </div>
              ) : (
                <div>
                  <span className='price'>{'$' + customerData!.currentBalance.toFixed(2)}</span>
                  <span>Total outstanding</span>
                </div>
              )}
              <DropDownArrow rotate={isOpen} />
            </div>
          </AmountButton>
          <SelectAmountDropdown style={{ display: isOpen ? 'block' : 'none' }}>
            <AmountButton
              disabled={customerData!.currentBalance <= 0}
              active={amountType === 'total'}
              onClick={e =>
                handlePaymentAmountSelect(e, customerData!.currentBalance.toFixed(2), 'total')
              }
            >
              <span className='price'>{'$' + customerData!.currentBalance.toFixed(2)}</span>
              <span>Total outstanding</span>
            </AmountButton>
            <AmountButton
              disabled={!isOverDue(statementData!.currentCharges, customerData!.currentBalance)}
              active={amountType === 'over'}
              onClick={e =>
                handlePaymentAmountSelect(
                  e,
                  (
                    customerData!.currentBalance -
                    parseFloat(statementData!.currentCharges.replace('$', ''))
                  ).toFixed(2),
                  'over'
                )
              }
            >
              <span className='price'>
                {overduePrice(statementData!.currentCharges, customerData!.currentBalance)}
              </span>
              <span>Overdue amount</span>
            </AmountButton>
          </SelectAmountDropdown>
        </>
      )}
    </StyledSelectAmount>
  );
};

export default SelectAmount;
