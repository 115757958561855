import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';
import useCustomerDetails from '../hooks/api/useCustomerDetails';
import bolt from '../images/bolt.svg';

const StyledGreeting = styled.div`
  max-width: 500px;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    width: 100%;
    margin: 0 auto 0 11px;
  }
`;
const Greeting = () => {
  const { data, isLoading, isIdle } = useCustomerDetails();

  if (isLoading || isIdle)
    return (
      <div>
        <h1>{<Skeleton />}</h1>
        <p>
          <Skeleton />
        </p>
      </div>
    );

  return (
    <StyledGreeting>
      <h1>Hi {data?.firstName},</h1>
      <p style={{ display: 'flex', alignItems: 'center' }}>
        <img src={bolt} alt='smart energy bolt' />
        <span>
          Account number: <strong>{data?.customerNumber}</strong>
        </span>
      </p>
    </StyledGreeting>
  );
};

export default Greeting;
