import React from 'react';
import styled from 'styled-components/macro';
import Navbar from './Navbar';

const StyledWrapper = styled.div`
  display: none;
  width: 100%;
  @media only screen and (min-width: 913px) {
    display: block;
  }
`;

const DesktopNav = () => {
  return (
    <StyledWrapper>
      <Navbar />
    </StyledWrapper>
  );
};

export default DesktopNav;
