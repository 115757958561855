import React from 'react';
import styled from 'styled-components/macro';

export const StyledControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  button {
    border: none;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Open Sans';
    font-weight: 700;
    border-radius: 4px;
  }
  label {
    font-weight: 600;
  }

  .edit {
    color: ${props => props.theme.smartBlue};
    background: none;
    margin-left: 5px;
  }
  .save {
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
    padding: 0px 12px;
    background: ${props => props.theme.smartBlue};
    color: white;
  }
`;

type Props = {
  title: string;
  editing: boolean;
  handleEditing: (editing: boolean) => void;
  handleSave: () => void;
  disabled?: boolean;
};
const InputControls = ({ title, editing, handleEditing, handleSave, disabled }: Props) => {
  return (
    <StyledControls>
      <label>{title}</label>
      <div>
        {editing ? (
          <button className='save' onClick={handleSave} disabled={disabled}>
            Save
          </button>
        ) : null}
        <button onClick={() => handleEditing(!editing)} className='edit'>
          {editing ? 'Cancel' : 'Edit'}
        </button>
      </div>
    </StyledControls>
  );
};

export default InputControls;
