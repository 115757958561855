import React, { useEffect, useState } from 'react';
import { auth } from '../service/firebase';
import { useHistory, useLocation } from 'react-router';

import { PrimaryButton, SecondaryButtonAnchor } from '../components/Buttons';

import Layout from '../components/Layout/Layout';
import { Link } from 'react-router-dom';
import Input from '../components/Input';
import LoginForm from '../components/SignInForm/LoginForm';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import { useAuthState } from 'react-firebase-hooks/auth';

const StyledSuccessMessage = styled.div`
  width: 100%;
  max-width: 402px;
  height: 48px;
  margin: 0 auto;
  padding: 12px 14px 12px 16px;
  border-radius: 4px;
  border: solid 1px #27c486;
  background-color: rgba(39, 196, 134, 0.07);
  font-size: 14px;
  font-weight: 600;
`;

const StyledOrBorder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  margin: 24px auto;
  span {
    text-align: center;
    display: block;
    background: white;
    flex-basis: 22px;
    padding: 0 10px;
    transform: translateY(-3px);
  }
  &:after,
  &:before {
    top: -5px;
    content: '';
    background-color: ${props => props.theme.inputBorder};
    width: 100%;
    height: 1px;
    display: block;
  }
`;

const SignInScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [user] = useAuthState(auth);
  useEffect(() => {
    // clear storage
    localStorage.clear();
  }, []);
  useEffect(() => {
    if (user) {
      history.replace('/overview');
    }
    return () => setIsLoading(false);
  }, [user, history]);

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      await auth.signInWithEmailAndPassword(email, password);
      setError(false);
      setIsLoading(false);
      history.replace('/overview');
    } catch (err: any) {
      setError(true);
      setIsLoading(false);
    }
  };

  const SignInHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signInWithEmailAndPassword(email, password);
  };

  return (
    <Layout pageTitle={'Customer Portal'} showError={error} resetError={setError}>
      {/* Render successful password updated message if location state was set from inside ResetPasswordScreen */}
      {location.state ? (
        <StyledSuccessMessage>
          <FontAwesomeIcon
            icon={faCheck}
            size={'1x'}
            color={'#27c486'}
            style={{ marginRight: '13px' }}
          />
          Password updated!
        </StyledSuccessMessage>
      ) : null}
      <LoginForm title='Log in to your account' onSubmit={SignInHandler}>
        <Input
          label='Email'
          type='text'
          onChange={setEmail}
          value={email}
          isError={error}
          placeholder='Email'
        />
        <Input
          placeholder='Password'
          label='Password'
          type='password'
          onChange={setPassword}
          value={password}
          isError={error}
          style={{ marginBottom: '22px' }}
        />
        {error ? (
          <p style={{ color: '#ef4444', fontWeight: 600 }}>Incorrect email or password</p>
        ) : null}

        <PrimaryButton type='submit' onClick={() => signInWithEmailAndPassword(email, password)}>
          {isLoading ? <ClipLoader color='#fff' size='15px' /> : 'Login'}
        </PrimaryButton>
        <Link to='/forgot-password' className='forgot-link'>
          Forgot your password?
        </Link>
        <StyledOrBorder>
          <span>or</span>
        </StyledOrBorder>
        <SecondaryButtonAnchor
          href='https://smartenergy.co/au/energy-plans/plans-pricing/'
          target='_blank'
          style={{ borderColor: 'black' }}
        >
          Sign up today!
        </SecondaryButtonAnchor>
      </LoginForm>
    </Layout>
  );
};

export default SignInScreen;
