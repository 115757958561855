import axios, { AxiosResponse } from 'axios';
import { BillingPeriod } from '../../types/BillPeriod';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import { ApiParamsAndHeaders } from '../../types/ApiParamsAndHeaders';
import { useQueryConfig } from '../useQueryConfig';

const getBillingPeriod = async (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const { data } = await axios.post<any, AxiosResponse<BillingPeriod>>(
    `${process.env.REACT_APP_API}/Usage/Period`,
    { ...apiParamsAndHeaders.apiParams },
    { ...apiParamsAndHeaders.headers }
  );
  return data;
};

export default function useBillingPeriod() {
  const { apiParamsAndHeaders } = useContext(UserContext);
  return useQuery<BillingPeriod, Error>(
    ['billingPeriod', apiParamsAndHeaders],
    () => getBillingPeriod(apiParamsAndHeaders),
    useQueryConfig(apiParamsAndHeaders)
  );
}
