import axios from 'axios';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserContext } from '../../context/UserContext';

export const useUpdatePhoneNumber = () => {
  const queryClient = useQueryClient();
  const { apiParamsAndHeaders } = useContext(UserContext);
  return useMutation<any, Error, any>(
    (changeValue: string) =>
      axios.post(
        `${process.env.REACT_APP_API}/Customer/Update`,
        {
          apiParams: [
            ...apiParamsAndHeaders.apiParams.apiParams,
            {
              key: 'phoneNumber',
              value: changeValue
            }
          ]
        },
        { ...apiParamsAndHeaders.headers }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customerDetails');
      }
    }
  );
};
