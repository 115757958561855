import styled from 'styled-components/macro';

export const CardColumn = styled.div`
  display: block;
  width: 100%;
`;

export const DesktopCardContainer = styled.div`
  display: none;
  width: 100%;
  @media only screen and (min-width: 1025px) {
    display: flex;
  }
`;

export const MobileCardContainer = styled.div`
  display: block;
  width: 100%;
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;
