import React from 'react';
import styled from 'styled-components/macro';
// Components
import Card from '../Card/Card';
// Assets
import feelGoodBg from '../../images/feel-good-bg.png';
import cancerCouncil from '../../images/cancer-council.svg';
import australianFlag from '../../images/australian-flag.png';
import smartSleep from '../../images/smartenergy-sleep.svg';

const StyledImageDetail = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    img {
      text-align: center;
      display: inline-block;
      height: 48px;
    }
  }
  .content {
    margin-left: 25px;
    display: inline-block;
    width: 206px;
  }
`;
const PlanCard = () => {
  return (
    <Card
      title='Feel Good Plan'
      imgSrc={feelGoodBg}
      textAlign='left'
      isDropDown={true}
      initialToggle={true}
    >
      <StyledImageDetail>
        <div className='image'>
          <img src={cancerCouncil} alt='Proudly supporting Cancer Council' />
        </div>
        <div className='content'>
          We donate <strong>$1 of every electricity bill</strong> to Cancer Council.
        </div>
      </StyledImageDetail>
      <StyledImageDetail>
        <div className='image'>
          <img src={smartSleep} alt='Moon and stars' />
        </div>
        <div className='content'>
          Sleep well at night, your electricity is <strong>100% carbon neutral.</strong>
        </div>
      </StyledImageDetail>
      <StyledImageDetail>
        <div className='image'>
          <img src={australianFlag} alt='Australian flag' />
        </div>
        <div className='content'>
          <strong>Aussie</strong> customer support at your service.
        </div>
      </StyledImageDetail>
    </Card>
  );
};

export default PlanCard;
