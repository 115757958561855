import { parse } from 'querystring';
import React from 'react';
import styled from 'styled-components/macro';
import { calcPercentage } from '../../utils/calcPercentage';
import { LocationModel } from '../Helpers/Location';
type Props = {
  paymentAmount: string;
  percentage: number;
  getLocation: LocationModel
};
const TransactionFees = ({ paymentAmount, percentage, getLocation }: Props) => {
  return (
    <StyledFees>
      <p>
      
      <strong>Total: {getLocation.currencySign}{paymentAmount != null && paymentAmount != '' ?  paymentAmount : '0.00'}</strong>
      </p>
     
      <p>
       {'+'}{percentage}{'%'} Transaction Fee
      </p>
    </StyledFees>
  );
};

const StyledFees = styled.div`
  text-align: right;
  margin-bottom: 32px;
`;

export default TransactionFees;
