import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';

type Props = {
  tip: string;
};

const StyledToolTip = styled.div`
  position: relative;
  cursor: pointer;
`;

const slideIn = keyframes`
	from {
		transform: translateX(10px) ;
		opacity: 0;
	}

	to {
		transform: translateX(0px) ;
		opacity: 100%;
	}
`;

const StyledTipCard = styled.div`
  position: absolute;
  height: auto;
  right: 0px;
  word-wrap: break-word;
  padding: 18px 14px 20px 22px;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeaeb;
  background-color: #fff;
  font-weight: 500;
  animation: ${slideIn} 0.3s ease-in-out;
  width: 254px;

  @media only screen and (min-width: 1024px) {
    top: 0px;
    right: -260px;
  }
`;

const ToolTip = ({ tip }: Props) => {
  const [toggle, setToggle] = useState(false);

  return (
    <StyledToolTip onMouseEnter={() => setToggle(true)} onMouseLeave={() => setToggle(false)}>
      {toggle ? (
        <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd'>
            <path d='M0 0h24v24H0z' />
            <path
              d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z'
              fill='#2B5CFF'
              fillRule='nonzero'
            />
          </g>
        </svg>
      ) : (
        <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
          <g fill='none' fillRule='evenodd' opacity='.3'>
            <path d='M0 0h24v24H0z' />
            <path
              d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z'
              fill={toggle ? '#2b5cff' : '#36373C'}
              fillRule='nonzero'
            />
          </g>
        </svg>
      )}
      {toggle ? <StyledTipCard>{tip}</StyledTipCard> : null}
    </StyledToolTip>
  );
};

export default ToolTip;
