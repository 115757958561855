import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';
import { UserContext } from '../../context/UserContext';

import useService from '../../hooks/api/useService';
import { Line } from '../Line';

const StyledGrayWrapper = styled.div`
  background: ${props => props.theme.smartGray};
  color: ${props => props.theme.smartDark};
  width: 100%;
  max-height: auto;
  padding: 24px 20px 30px 24px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.borderColor};
  h2 {
    margin-top: 0px;
    font-size: 20px;
  }
  h3 {
    padding-top: 16px;
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
  }
  hr {
    background: ${props => props.theme.borderColor};
  }
  p {
    white-space: pre-line;
  }
  @media only screen and (min-width: 768px) {
    min-height: 361px;
    width: 296px;
    margin-left: 1em;
  }
`;

const GrayDetails = () => {
  const { loggedInUser } = useContext(UserContext);
  // const { data, isLoading, isError } = useCustomerDetails();
  const { data, isLoading, isError } = useService();
  // const addressString = () => {
  //   const { homeAddress, homeSuburb, homePostalCode, homeState } = data!;
  //   return `${homeAddress}, \n ${homeSuburb} ${homeState} ${homePostalCode}`;
  // };

  if (isLoading)
    return (
      <StyledGrayWrapper>
        <Skeleton width='100%' />
        <Skeleton width='100%' />
        <Skeleton width='100%' />
        <Skeleton width='100%' />
        <Skeleton width='100%' />
        <Skeleton width='100%' />
        <Skeleton width='100%' />
        <Skeleton width='100%' />
      </StyledGrayWrapper>
    );

  if (isError) return null;
  return (
    <StyledGrayWrapper>
      <h2>Account Details</h2>
      <h3 style={{ paddingTop: 0 }}>Account number</h3>
      <p>{loggedInUser.customerNumber}</p>
      <Line />
      <h3>National Meter Identifier (NMI)</h3>
      <p>{data?.nmi}</p>
      <Line />
      <h3>Supply address</h3>
      <p>{data?.address}</p>
    </StyledGrayWrapper>
  );
};

export default GrayDetails;
