import { AxiosResponse } from 'axios';
import { useEffect } from 'react';

import { UseMutationResult } from 'react-query';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
  mutation: UseMutationResult<AxiosResponse<any>, unknown, string, unknown>;
};

const StyledSaveStatus = styled.span<Props>`
  display: block;
  color: ${props =>
    props.mutation.isSuccess
      ? props.theme.smartGreen
      : props.mutation.isError
      ? props.theme.smartRed
      : ''};
  font-size: 14px;
  border-radius: 4px;
  padding: 0px 3px;
  display: inline-block;
`;

const SaveStatus: React.FC<Props> = ({ mutation, children }) => {
  useEffect(() => {
    let statusTimeout = setTimeout(() => {
      if (mutation.isSuccess || mutation.isError) {
        mutation.reset();
      }
    }, 2000);

    return () => {
      clearTimeout(statusTimeout);
    };
  }, [mutation]);

  return (
    <StyledSaveStatus mutation={mutation}>
      {mutation.isSuccess ? (
        <FontAwesomeIcon icon={faCheckCircle} size='1x' />
      ) : mutation.isError ? (
        <FontAwesomeIcon icon={faExclamationCircle} size='1x' />
      ) : null}{' '}
      {children}
    </StyledSaveStatus>
  );
};

export default SaveStatus;
