import React from 'react';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import boltImg from '../../images/lightning.gif';

type Props = {
  logoutHandler: () => void;
  user: firebase.User | null | undefined;
};

const StyledLoginLogoutButton = styled.div`
  right: 0;
  position: absolute;
  .logout {
    min-width: 130px;
    font-weight: 600;
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    padding: 1em;
    line-height: 54px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    min-width: 130px;
    text-align: center;
    color: ${props => props.theme.smartDark};
    &:before {
      content: '';
      display: block;
      width: 17px;
      height: 24px;
      background-image: url(${boltImg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 5px;
    }
    @media only screen and (min-width: 1024px) {
      padding: 0 40px;
    }
  }
`;
const LoginLogoutButton = ({ logoutHandler, user }: Props) => {
  return (
    <StyledLoginLogoutButton>
      {user ? (
        <button className='logout' onClick={() => logoutHandler()}>
          Logout
        </button>
      ) : (
        <Link className='logout' to='/'>
          Login
        </Link>
      )}
    </StyledLoginLogoutButton>
  );
};

export default LoginLogoutButton;
