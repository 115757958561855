import { Link } from 'react-router-dom';
import { StyledControls } from './StyledControls';
import { StyledEditDetailWrapper } from './StyledEditDetailWrapper';

const EditPassword = () => {
  return (
    <StyledEditDetailWrapper>
      <StyledControls>
        <label>Password</label>
        <Link to='/reset-password'>Update</Link>
      </StyledControls>
      <p></p>
    </StyledEditDetailWrapper>
  );
};

export default EditPassword;
