import axios from 'axios';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';

import { StripePayment } from '../../types/StripePayment';
import { calcPercentage } from '../../utils/calcPercentage';

import { PrimaryButton } from '../Buttons';
import { LocationModel } from '../Helpers/Location';
import PaymentAmountInput from './PaymentAmountInput';
import TransactionFees from './TransactionFees';

type StripeComponentProps = {
  amount: string;
  salesOrderNo: string;
  amountError: boolean;
  disabled: boolean;
  onChange: (e: string) => void;
  getLocation : LocationModel
};

export const StripeComponent = ({
  amount,
  salesOrderNo,
  amountError,
  disabled,
  onChange,
  getLocation
}: StripeComponentProps) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setIsLoading(true);
    const stripeOptions = new StripePayment(
     finalAmount,
      salesOrderNo,
      getLocation.currency,
      'SMART ENERGY | Solar',
      getLocation.timeZone
    );

    axios
      .post(`${process.env.REACT_APP_API}/Payment/Stripe`, stripeOptions)
      .then(res => {
        setIsLoading(false);
        window.localStorage.setItem(
          'stripeOrder',
          JSON.stringify({ sessionId: res.data.sessionId, salesOrderNo })
        );
        window.location = res.data.returnUrl;
      })

      .catch(err => {
        setError(err.title);
        setIsLoading(false);
      });
  };
 const percentageAmount = getLocation.hidePayForEnergy ? 1.44 : 1.82;
 const finalAmount = parseFloat(amount) +  calcPercentage(percentageAmount, parseFloat(amount));
 console.log(finalAmount);
  return (
    <section>
      <div>
        <PaymentAmountInput
          isError={amountError}
          amount={amount}
          label={'Enter amount to pay'}
          errorMessage={'Enter the amount on your sales order'}
          onChange={onChange}
          getLocation={getLocation}
        />

        <TransactionFees paymentAmount={amount} percentage={getLocation.hidePayForEnergy ? 1.44 : 1.82} getLocation={getLocation} />

        {isLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <ClipLoader color={'#2b5cff'} />
          </div>
        ) : (
          <PrimaryButton onClick={handleSubmit} style={{ marginTop: '30px' }} disabled={disabled}>
            Checkout
          </PrimaryButton>
        )}
      </div>
    </section>
  );
};

export const Message = ({ message }: { message: string }) => (
  <section>
    <p>{message}</p>
  </section>
);
