import React from 'react';
type Props = {
  style: {};
};
const Chevron = ({ style }: Props) => {
  return (
    <div style={{ display: 'block', ...style }}>
      <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 15l7-7 7 7' />
      </svg>
    </div>
  );
};

export default Chevron;
