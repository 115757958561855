import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import Input from '../components/Input';
import LoginForm, { StyledLoginContainer } from '../components/SignInForm/LoginForm';
import { PrimaryButton } from '../components/Buttons';
import { auth } from '../service/firebase';
import { ClipLoader } from 'react-spinners';

type ForgotPasswordScreenProps = {
  loggedIn?: boolean;
  welcome?: boolean;
};

const ForgotPasswordScreen = ({ loggedIn = false, welcome = false }: ForgotPasswordScreenProps) => {
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sendForgotPasswordEmail = (Email: string) => {
    setIsLoading(true);
    auth
      .sendPasswordResetEmail(Email)
      .then(function () {
        setError(false);
        setIsSuccess(true);
        setIsLoading(false);
      })
      .catch(function (err) {
        console.debug(err);
        setIsSuccess(false);
        setError(true);
        setIsLoading(false);
      });
  };

  const submitPasswordResetEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendForgotPasswordEmail(email);
  };

  return (
    <Layout pageTitle={welcome ? 'Welcome!' : 'Change your password'}>
      {!isSuccess ? (
        <LoginForm
          onSubmit={submitPasswordResetEmail}
          title={
            welcome
              ? 'Welcome to\nSmart Energy'
              : loggedIn
              ? 'Want to change your password?'
              : 'Forgot your password?'
          }
          description={
            welcome
              ? 'To get started, we need to confirm your email address that we have on file.  You will recieve a confirmation email from us asking you to set your password.'
              : 'No worries! Just fill in your email and we’ll send you a link to reset your password.'
          }
        >
          <Input
            label='Email'
            type='text'
            onChange={setEmail}
            value={email}
            isError={error}
            placeholder='Email'
            style={{ marginBottom: '16px' }}
          />

          {isLoading ? (
            <ClipLoader color={'#2b5cff'} />
          ) : (
            <PrimaryButton onClick={() => sendForgotPasswordEmail(email)}>
              Confirm email address
            </PrimaryButton>
          )}
          {error ? (
            <>
              <p>The email you have entered doesn’t match our records.</p>
              <p>
                Please contact customer service on <a href='tel:1300133055'>1300 133 055</a> if you
                think your email is right and still can’t reset your password.
              </p>
            </>
          ) : null}
        </LoginForm>
      ) : (
        <StyledLoginContainer>
          <h1>Check your email!</h1>
          <p>
            We’ve sent a link to <strong>{email}</strong>. Please follow the instructions in the
            email to set your password.
          </p>
          <h3>Didn't get it?</h3>
          <p>
            Give it a minute. Also remember to check your spam folder. Sometimes emails end up
            there.
          </p>
          <PrimaryButton onClick={() => setIsSuccess(false)}>Send again</PrimaryButton>
        </StyledLoginContainer>
      )}
    </Layout>
  );
};

export default ForgotPasswordScreen;
