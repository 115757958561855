import styled from 'styled-components/macro';

const StyledBpayInfo = styled.div`
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 900;
    }
  }
`;

const BPayInfo = () => (
  <>
    <h3 style={{ textAlign: 'center' }}>Pay with BPAY</h3>
    <p style={{ textAlign: 'center' }}>
      Make a payment directly from your Australian Bank account using the reference number.
    </p>
    <StyledBpayInfo>
      <div className='info'>
        <span>Biller code</span> <p>361030</p>
      </div>
      <div className='info'>
        <span>Reference</span> <p>700000002</p>
      </div>
    </StyledBpayInfo>
  </>
);

export default BPayInfo;
