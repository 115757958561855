import Skeleton from 'react-loading-skeleton';
import useStatements from '../../hooks/api/useStatements';
import { convertPriceToNumber } from '../../utils/convertPriceToNumber';

import { makeValidDate } from '../../utils/makeValidDate';

import Bill from './Bill';

const BillingList = () => {
  const { data, isLoading, isIdle, isError } = useStatements();

  const isDue = (issuedDate: Date, dueDate: Date) => {
    const todaysDate = new Date();

    if (todaysDate.getTime() > dueDate.getTime()) {
      return 'overdue';
    } else if (
      todaysDate.getTime() >= issuedDate.getTime() &&
      todaysDate.getTime() <= dueDate.getTime()
    ) {
      return 'due';
    } else return '';
  };

  if (isLoading || isIdle) return <Skeleton count={10} height={76} />;

  let allPaid: boolean = false;
  if (data && convertPriceToNumber(data[0].currentBalance) <= 0) {
    allPaid = true;
  }

  if (isError)
    return (
      <div>
        <h3>An error occurred</h3>
        <p>Cannot display information at this time, please try refreshing the page.</p>
      </div>
    );

  return (
    <div>
      {data!.length <= 0 ? (
        <p>No statements</p>
      ) : (
        data?.map(statement => (
          <Bill
            key={statement.statementNumber}
            price={statement.currentCharges}
            startDate={statement.billingPeriodStart}
            endDate={statement.billingPeriodEnd}
            statementNumber={statement.statementNumber}
            status={
              allPaid
                ? 'paid'
                : isDue(makeValidDate(statement.issuedDate), makeValidDate(statement.dueDate))
            }
          />
        ))
      )}
    </div>
  );
};

export default BillingList;
