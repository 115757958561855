import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Layout from '../components/Layout/Layout';
import { logout } from '../service/firebase';
const UnauthorisedScreen = () => {
  const location = useLocation<{ error: Error }>();
  const history = useHistory();

  useEffect(() => {
    if (!location?.state?.error) {
      history.replace('/overview');
    }

    let timer = setTimeout(() => {
      logout();
      history.replace('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [history, location]);
  return (
    <Layout pageTitle={'Oops something went wrong'}>
      <p>Oops something went wrong. We are redirecting you back to the login screen.</p>
    </Layout>
  );
};

export default UnauthorisedScreen;
