import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { Statement } from '../../types/Statement';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import { ApiParamsAndHeaders } from '../../types/ApiParamsAndHeaders';
import { useQueryConfig } from '../useQueryConfig';

const getStatements = async (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const { data } = await axios.post<any, AxiosResponse<Statement[]>>(
    `${process.env.REACT_APP_API}/Statement/All`,
    { ...apiParamsAndHeaders.apiParams },
    { ...apiParamsAndHeaders.headers }
  );
  return data;
};

export default function useStatements() {
  const { apiParamsAndHeaders } = useContext(UserContext);
  return useQuery<Statement[], Error>(
    ['statements', apiParamsAndHeaders],
    () => getStatements(apiParamsAndHeaders),
    useQueryConfig(apiParamsAndHeaders)
  );
}
