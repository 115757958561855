import moment from 'moment';

export const isExpiryDateValid = (month: string, year: string) => {
  if (month === '' && year === '') return false;

  // Create string with MM/YY format
  const todaysDate = `${(new Date().getMonth() + 1).toString().padStart(2, '0')}/${new Date()
    .getFullYear()
    .toString()
    .substr(-2)}`;

  const today = moment(todaysDate, 'MM/YY');
  const expiryDate = moment(`${month}/${year}`, 'MM/YY');

  // IF month and year contain 2 digits AND today is before expiry date RETURN TRUE
  // OR IF today is the same as expiry date but todays date is before the last day of month RETURN TRUE
  // ELSE RETURN FALSE
  if (
    (month.length === 2 && year.length === 2 && today.isBefore(expiryDate)) ||
    (today.isSame(expiryDate) && moment().date() !== expiryDate.daysInMonth())
  ) {
    return false;
  } else {
    return true;
  }
};
