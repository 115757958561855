const PoliInfo = () => (
  <div style={{ textAlign: 'center' }}>
    <h3>Pay with POLi</h3>
    <p>
      Pay with your bank account using Internet Banking. You’ll be redirected to POLi to complete
      your payment.
    </p>
  </div>
);

export default PoliInfo;
