import React from 'react';

export function CalculateLocation(countryCode : string){
    var locationModel :LocationModel ;
    
    if(countryCode.includes("US") || countryCode.includes("USA")){
       locationModel = new LocationModel(true, true, "USD", "$", countryCode);
    }
    else if (countryCode.includes("GB") || countryCode.includes("GBR")){
        locationModel = new LocationModel(true, true, "GBP", "£", countryCode);
    }
    else{
        locationModel = new LocationModel(false, false, "AUD", "$", countryCode);
    }
    return locationModel;
}

export class LocationModel {
    constructor(
        public hideLogin: boolean,
        public hidePayForEnergy: boolean,
        public currency: string,
        public currencySign : string,
        public timeZone : string
    ) { }
}