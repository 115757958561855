// Components
import Layout from '../components/Layout/Layout';
import UsageCard from '../components/Overview/UsageCard';
import DirectDebitCard from '../components/Overview/DirectDebitCard';
import AccountBalanceCard from '../components/Overview/AccountBalanceCard';
import PlanCard from '../components/Overview/PlanCard';
import BillingCard from '../components/Overview/BillingCard';
import {
  CardColumn,
  DesktopCardContainer,
  MobileCardContainer
} from '../components/Card/CardContainers';
import Greeting from '../components/Greeting';
import AddressSelect from '../components/Overview/AddressSelect';
import DinosaurCard from '../components/Overview/DinosaurCard';

const OverviewScreen = () => {
  return (
    <Layout pageTitle={'Account Overview'} background='#fbfbfb'>
      <div style={{ margin: '0 auto' }}>
        <Greeting />
        <AddressSelect />
      </div>
      {/* Two column layout */}
      <DesktopCardContainer>
        <CardColumn>
          <AccountBalanceCard />
          <DirectDebitCard />
          <DinosaurCard />
        </CardColumn>
        <CardColumn>
          <BillingCard />
          <PlanCard />
        </CardColumn>
      </DesktopCardContainer>
      {/* One column layout */}
      <MobileCardContainer>
        <CardColumn>
          <AccountBalanceCard />
          <BillingCard />
          <PlanCard />
          <DirectDebitCard />
        </CardColumn>
      </MobileCardContainer>
    </Layout>
  );
};

export default OverviewScreen;
