import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { PrimaryButtonLink, SecondaryButton } from '../components/Buttons';
import Header from '../components/Layout/Header';
import { UserContext } from '../context/UserContext';
import { BPointSuccess } from '../types/BPointPayment';

const StyledHeader = styled.div`
  padding: 54px 0px 0px 0px;
  text-align: center;
  margin-top: 54px;
  .success-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: ${props => props.theme.smartGreen};
    margin: 0 auto;
  }
`;

const StyledContainer = styled.div`
  max-width: 90%;
  width: 405px;
  margin: 32px auto 0px auto;
  .buttons {
    margin-top: 31px;
    display: flex;
    justify-content: space-between;
  }
`;

const StyledPaymentDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: solid 1px ${props => props.theme.borderColor};
  span {
    font-weight: 900;
  }
  p {
  }
`;

const PaymentSuccessScreen = () => {
  const location = useLocation<BPointSuccess>();

  const successResponse = location.state;
  const { loggedInUser } = useContext(UserContext);

  const history = useHistory();

  useEffect(() => {
    if (!successResponse) {
      history.replace('/');
    }
  }, [location, history, successResponse]);

  return (
    <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      <Header />
      <StyledHeader>
        <div className='success-icon'>
          <FontAwesomeIcon icon={faCheck} color='#fff' />
        </div>
        <h1>Payment completed</h1>
        <p>Your payment was accepted and receipt has been sent to your email.</p>
      </StyledHeader>
      <StyledContainer>
        <StyledPaymentDetail>
          <span>Sales order number</span>
          <p>{loggedInUser?.customerNumber}</p>
        </StyledPaymentDetail>
        <StyledPaymentDetail>
          <span>Name</span>
          <p>{successResponse?.name}</p>
        </StyledPaymentDetail>
        <StyledPaymentDetail>
          <span>Email sent to</span>
          <p>{successResponse?.email}</p>
        </StyledPaymentDetail>
        <StyledPaymentDetail>
          <span>Payment type</span>
          <p>Card ending {successResponse?.data.maskedCardNo}</p>
        </StyledPaymentDetail>
        <StyledPaymentDetail>
          <span>Payment amount</span>
          <p>
            {'$'}
            {successResponse?.paymentAmount}
          </p>
        </StyledPaymentDetail>
        <StyledPaymentDetail>
          <span>Payment date</span>
          <p>{moment(successResponse?.data.processDateTime).format('Do MMMM yy')}</p>
        </StyledPaymentDetail>
        <StyledPaymentDetail>
          <span>Receipt</span>
          <p>{successResponse?.data.receiptNumber}</p>
        </StyledPaymentDetail>
        <div className='buttons' id='ignore-print'>
          <SecondaryButton style={{ width: '189px' }} onClick={window.print}>
            Print
          </SecondaryButton>
          <PrimaryButtonLink to='/pay' style={{ width: '189px' }}>
            Pay another bill
          </PrimaryButtonLink>
        </div>
      </StyledContainer>
    </div>
  );
};

export default PaymentSuccessScreen;
