import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../components/Buttons';
import { auth } from '../service/firebase';
import Layout from '../components/Layout/Layout';
import LoginForm from '../components/SignInForm/LoginForm';
import Input from '../components/Input';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
type Props = {
  title: string;
};
const ResetPasswordScreen = ({ title }: Props) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState(false);
  const [validCharactersError, setValidError] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const history = useHistory();
  const confirmationCode = new URLSearchParams(location.search).get('oobCode');

  useEffect(() => {
    if (!location.search) {
      history.replace('/');
    }
    return () => {
      setIsSuccessful(false);
    };
  }, [confirmPassword, location, history]);

  useEffect(() => {
    if (isSuccessful) {
      history.replace('/', isSuccessful);
    }
  }, [isSuccessful, history]);

  const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?]).+$');

  const changePasswordHandler = (value: string) => {
    setPassword(value);
    if (pattern.test(value) && value.length >= 8) {
      setValidError(false);
    } else {
      setValidError(true);
    }
    if (confirmPassword !== value) {
      setMatchError(true);
    } else {
      setMatchError(false);
    }
  };

  const changeConfirmPasswordHandler = (value: string) => {
    setConfirmPassword(value);
    if (pattern.test(value) && value.length >= 8) {
      setValidError(false);
    } else {
      setValidError(true);
    }
    if (password !== value) {
      setMatchError(true);
    } else {
      setMatchError(false);
    }
  };

  const PasswordResetClickHandler = () => {
    if (confirmationCode && password === confirmPassword) {
      auth
        .confirmPasswordReset(confirmationCode, password)
        .then(res => setIsSuccessful(true))
        .catch(err => {
          setError(true);
          if (err.code === 'auth/invalid-action-code')
            setErrorMessage('Session may be invalid or expired');
          console.debug(err);
        });
    }
  };

  const passwordResetSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    PasswordResetClickHandler();
  };

  return (
    <Layout pageTitle={'New password'}>
      <LoginForm
        title={title}
        description='Create a new, strong password that you don’t use for other websites.'
        onSubmit={passwordResetSubmitHandler}
      >
        <p>
          The password should be at least 8 characters, contain one lowercase character, one
          uppercase character and one symbol.
        </p>

        <Input
          isError={validCharactersError || matchError}
          label='Create new password'
          value={password}
          type='password'
          onChange={changePasswordHandler}
          placeholder='New password'
        />
        <Input
          isError={validCharactersError || matchError}
          label='Confirm'
          value={confirmPassword}
          type='password'
          errorMessage={
            validCharactersError
              ? 'Password should be at least 8 characters, contain one lowercase character, one uppercase character, and one symbol.'
              : 'Passwords must match.'
          }
          onChange={changeConfirmPasswordHandler}
          placeholder='Confirm password'
          style={{ marginBottom: '16px' }}
        />
        {error ? (
          <p>
            {errorMessage} <Link to='/forgot-password'>please request another password reset</Link>
          </p>
        ) : (
          <PrimaryButton
            onClick={() => PasswordResetClickHandler()}
            disabled={
              matchError || confirmPassword === '' || password === '' || validCharactersError
            }
          >
            Save password
          </PrimaryButton>
        )}
      </LoginForm>
    </Layout>
  );
};

export default ResetPasswordScreen;
