import React from 'react';

import styled from 'styled-components/macro';
import { ExternalTabLink, TabLink } from './Tabs';

const StyledNavbar = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: auto;
  margin: 0 auto;
  overflow: auto;
  @media only screen and (min-width: 768px) {
    overflow: hidden;
    justify-content: center;
  }
`;

const Navbar = () => {
  return (
    <StyledNavbar>
      <TabLink activeClassName='selected' to='/overview'>
        Overview
      </TabLink>
      <TabLink activeClassName='selected' to='/bills'>
        Bills
      </TabLink>
      <TabLink activeClassName='selected' to='/details'>
        My details
      </TabLink>
      <TabLink activeClassName='selected' to='/pay'>
        Pay my bill
      </TabLink>
      <ExternalTabLink href='https://smartenergy.co/au/help-support/'>
        Help & Support
      </ExternalTabLink>
    </StyledNavbar>
  );
};

export default Navbar;
