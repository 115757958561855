import React from 'react';
import styled from 'styled-components/macro';

const StyledDetail = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 16px;
`;

type Props = {
  title: string;
  detail: string | number;
};
const DetailItem = ({ title, detail }: Props) => {
  return (
    <StyledDetail>
      <span style={{ maxWidth: '130px' }}>{title}</span>
      <span>{detail}</span>
    </StyledDetail>
  );
};

export default DetailItem;
