import React from 'react';
import { ClipLoader } from 'react-spinners';
import Layout from '../components/Layout/Layout';

const LoadingScreen = () => {
  return (
    <Layout pageTitle={'Loading...'}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ClipLoader color={'#2b5cff'} />
      </div>
    </Layout>
  );
};

export default LoadingScreen;
