import styled from 'styled-components/macro';
import ToolTip from './ToolTip';

type Props = {
  prefix?: string;
  label: string;
  type: string;
  value: string;
  placeholder: string;
  isError?: boolean;
  disabled?: boolean;
  onChange: (e: string) => void;
  errorMessage?: string;
  style?: React.CSSProperties;
  tip?: string;
  autoComplete?: string;
  maxLength?: number;
  minLength?: number;
  info?: string | number;
};

export const StyledInput = styled.input<{ isError: boolean }>`
  border: solid 1px ${props => (props.isError ? props.theme.smartRed : props.theme.inputBorder)};
  background: #fff;
  border-radius: 4px;
  padding-left: 10px;
  margin-top: 16px;
  font-size: 16px;
  height: 46px;
  width: 100%;
  &:disabled {
    font-weight: 600;
    cursor: not-allowed;
  }
`;

const Input = ({
  prefix = '',
  label,
  value,
  type,
  maxLength,
  minLength,
  disabled = false,
  onChange,
  placeholder,
  isError = false,
  errorMessage,
  style,
  autoComplete = 'on',
  tip = '',
  info = ''
}: Props) => {
  return (
    <label>
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 600,
          marginTop: '16px',
          right: '0px'
        }}
      >
        {label}
        {tip ? <ToolTip tip={tip} /> : null}
        {info ? <span>{info}</span> : null}
      </span>
      <StyledInput
        maxLength={maxLength}
        minLength={minLength}
        style={style}
        disabled={disabled}
        autoComplete={autoComplete}
        isError={isError}
        type={type}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
      {isError && (
        <span
          style={{
            display: 'block',
            fontWeight: 600,
            color: '#ef4444',
            marginTop: '8px',
            marginBottom: '16px'
          }}
        >
          {errorMessage}
        </span>
      )}
    </label>
  );
};
export default Input;
