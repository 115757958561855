import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { RatePlan } from '../../types/RatePlan';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import { ApiParamsAndHeaders } from '../../types/ApiParamsAndHeaders';
import { useQueryConfig } from '../useQueryConfig';

const getRatePlan = async (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const { data } = await axios.post<any, AxiosResponse<RatePlan>>(
    `${process.env.REACT_APP_API}/RatePlan/All`,
    { ...apiParamsAndHeaders.apiParams },
    { ...apiParamsAndHeaders.headers }
  );
  return data;
};

export default function useRatePlan() {
  const { apiParamsAndHeaders } = useContext(UserContext);
  return useQuery<RatePlan, Error>(
    ['ratePlanDetails', apiParamsAndHeaders],
    () => getRatePlan(apiParamsAndHeaders),
    useQueryConfig(apiParamsAndHeaders)
  );
}
