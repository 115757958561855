import useBillingPeriod from '../../hooks/api/useBillingPeriod';
import { calcDayDifference } from '../../utils/calcDayDifference';
import { convertDate } from '../../utils/convertDate';
// Components
import Card from '../Card/Card';
import SkeletonCard from '../Card/SkeletonCard';
import ProgressBar from './ProgressBar';

const BillingCard = () => {
  const { data, isLoading, isError, isIdle } = useBillingPeriod();

  if (isLoading || isIdle) return <SkeletonCard count={7} />;

  if (isError || !data)
    return (
      <Card title='Information unavailable' isError={isError}>
        Oops, something went wrong. Please refresh the page.
      </Card>
    );

  const daysLeft = calcDayDifference(new Date().toDateString(), data!.end);
  return (
    <Card
      title='Billing Period'
      textAlign='right'
      button='secondary'
      linkUrl='/bills'
      buttonText='Bills'
    >
      <h3 style={{ textAlign: 'center' }}>{`${daysLeft <= 1 ? 0 : daysLeft} days left`}</h3>
      {/* <p>{`${calcDayDifference(data!.start, data!.end)} Days`} </p> */}
      <ProgressBar start={convertDate(data!.start)} end={convertDate(data!.end)} />
    </Card>
  );
};

export default BillingCard;
