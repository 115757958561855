import { useContext } from 'react';
import styled from 'styled-components/macro';
import { AvailableCustomerNumber, UserContext } from '../../context/UserContext';

import selectArrows from '../../images/select-arrows.svg';

const StyledAddressHeaderWrapper = styled.div`
  width: 100%;
  height: 45px;
  border-bottom: solid 1px ${props => props.theme.borderColor};
  background-color: #fff;
`;

const StyledAddressHeaderContainer = styled.div`
  max-width: 1920px;
  width: 90%;
  height: 100%;
  margin: 0px auto 0px auto;
  @media only screen and (min-width: 1024px) {
    margin: 54px auto 0px auto;
  }
  display: flex;
  justify-content: flex-end;
  position: relative;

  select {
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 16px;
    width: 370px;
  }

  &:after {
    position: absolute;
    right: 0px;
    top: 13px;
    z-index: 999;
    content: '';
    display: block;
    width: 10px;
    height: 16px;
    background-size: cover;
    background-color: #fff;
    background-image: url(${selectArrows});
  }
`;

const AddressHeader = () => {
  const { loggedInUser, handleSwitchCustomerNumber } = useContext(UserContext);

  return (
    <StyledAddressHeaderWrapper>
      <StyledAddressHeaderContainer>
        <select
          value={loggedInUser.customerNumber}
          onChange={e => handleSwitchCustomerNumber(e.target.value)}
        >
          {loggedInUser.availableCustomerNumbers.map((customer: AvailableCustomerNumber) => (
            <option key={customer.customerNumber} value={customer.customerNumber}>
              {customer.displayValue}
            </option>
          ))}
        </select>
      </StyledAddressHeaderContainer>
    </StyledAddressHeaderWrapper>
  );
};

export default AddressHeader;
