import React from 'react';
import styled from 'styled-components/macro';

const StyledReadDetail = styled.div`
  max-width: 459px;
  span {
    display: block;
    font-weight: 600;
  }
`;

type Props = {
  title: string;
  detail?: string;
};
const ReadDetail = ({ title, detail }: Props) => {
  return (
    <StyledReadDetail>
      <span>{title}</span>
      <p>{detail}</p>
    </StyledReadDetail>
  );
};

export default ReadDetail;
