import React, { useState } from 'react';
import styled from 'styled-components/macro';

import Input from '../Input';

import stripeLogo from '../../images/stripe-logo.png';
import SecurePaymentsLogos from './SecurePaymentsLogos';
import PaymentOptions from './PaymentOptions';

import { StripeComponent } from './StripeComponent';
import PoliInfo from './PoliInfo';
import BPayInfo from './BPayInfo';
import { LocationModel } from '../Helpers/Location';

const StyledFees = styled.div`
  text-align: right;
  margin-bottom: 32px;
`;

const StyledFieldWrapper = styled.div`
  max-width: 405px;
  width: 90%;
  margin: 0 auto;
`;
type Props = {
  getLocation: LocationModel;
 
};
const SolarPaymentForm = ({getLocation} : Props) => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [salesOrderNo, setSalesOrderNo] = useState('');

  const [amountError, setAmountError] = useState(false);
  // const [customerEmailError, setCustomerEmailError] = useState(false);
  const [customerNameError, setCustomerNameError] = useState(false);
  const [salesOrderNoError, setSalesOrderNoError] = useState(false);

  const [option, setOption] = useState<'credit-card' | 'poli' | 'bpay'>('credit-card');

  const enableButton = () =>
    [customerName, customerEmail, salesOrderNo, paymentAmount].some(value => value.trim() === '') ||
    [amountError, customerNameError, salesOrderNoError].some(value => value === true);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handlePaymentOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value as 'credit-card' | 'poli' | 'bpay');
  };

  const handleCustomerName = (value: string) => {
    setCustomerName(value);
    if (value === '') {
      setCustomerNameError(true);
    } else {
      setCustomerNameError(false);
    }
  };

  const handleSalesOrderNumber = (value: string) => {
    setSalesOrderNo(value);
    if (value === '') {
      setSalesOrderNoError(true);
    } else {
      setSalesOrderNoError(false);
    }
  };

  const handlePaymentAmount = (value: string) => {
    setPaymentAmount(value);

    if (value === '') {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledFieldWrapper>
        <Input
          tip='Your Sales Order Number is found in the … [add text]'
          isError={salesOrderNoError}
          errorMessage='Sales order number is required'
          label='Sales order number'
          type='text'
          placeholder=''
          value={salesOrderNo}
          onChange={handleSalesOrderNumber}
        />
        <Input
          isError={customerNameError}
          errorMessage='Customer name is required'
          label='Customer name'
          type='text'
          placeholder=''
          value={customerName}
          onChange={handleCustomerName}
        />
        <Input
          // isError={customerEmailError}
          errorMessage='Enter your email so that we can send you a receipt'
          label='Customer email'
          type='text'
          placeholder=''
          value={customerEmail}
          onChange={setCustomerEmail}
        />
      </StyledFieldWrapper>
      <PaymentOptions
        handlePaymentOptionChange={handlePaymentOptionChange}
        option={option}
        bpay={false}
        fees={true}
        getLocation={getLocation}
      />

      <StyledFieldWrapper>
        {option === 'credit-card' ? (
          <div style={{ textAlign: 'center' }}>
            <h3>Pay with debit or credit card</h3>
            <p>You’ll be redirected to Stripe to complete your payment.</p>

            {option === 'credit-card' && (
              <StripeComponent
                disabled={enableButton()}
                salesOrderNo={salesOrderNo}
                amountError={amountError}
                amount={paymentAmount}
                onChange={handlePaymentAmount}
                getLocation={getLocation}
              />
            )}
          </div>
        ) : option === 'poli' ? (
          <PoliInfo />
        ) : (
          <BPayInfo />
        )}

        <SecurePaymentsLogos img1={stripeLogo} />
      </StyledFieldWrapper>
    </form>
  );
};

export default SolarPaymentForm;
