import { calcPercentage } from '../utils/calcPercentage';

export type CardDetails = {
  cardHolderName: string;
  cardNumber: string;
  cvn: string;
  expiryDate: string;
};

export class PaymentPayload {
  amountSurcharge: number;

  constructor(
    public amount: number,
    public currency: string,
    public merchantReference: string,
    public customerNumber: string,
    public emailAddress: string,
    public cardDetails: CardDetails
  ) {
    this.amountSurcharge = parseInt(calcPercentage(1.78, amount).toString());
  }
}
