import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import { ApiParamsAndHeaders } from '../../types/ApiParamsAndHeaders';
import { useQueryConfig } from '../useQueryConfig';
import { Statement } from '../../types/Statement';

const getStatementDetails = async (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const { data } = await axios.post<Statement, AxiosResponse<any>>(
    `${process.env.REACT_APP_API}/Statement/Details`,
    { ...apiParamsAndHeaders.apiParams },
    { ...apiParamsAndHeaders.headers }
  );

  return data;
};

export default function useStatementDetails() {
  const { apiParamsAndHeaders } = useContext(UserContext);

  return useQuery<Statement, Error>(
    ['statementDetails', apiParamsAndHeaders],
    () => getStatementDetails(apiParamsAndHeaders),
    useQueryConfig(apiParamsAndHeaders)
  );
}
