import React from 'react';
type Props = {
  rotate: boolean;
};
const DropDownArrow = ({ rotate }: Props) => {
  return (
    <div>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        style={{
          transition: 'transform 0.2s ease-in-out',
          transform: rotate ? `rotate(180deg)` : ''
        }}
      >
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h24v24H0z' />
          <path fill='#36373C' fillRule='nonzero' d='m7 10 5 5 5-5z' />
        </g>
      </svg>
    </div>
  );
};

export default DropDownArrow;
