import React, { useState } from 'react';

import { ClipLoader } from 'react-spinners';

import { useUpdatePhoneNumber } from '../../../hooks/api/useUpdatePhoneNumber';

import InputControls from './InputControls';

import SaveStatus from './SaveStatus';
import styled from 'styled-components/macro';

type Props = {
  title: string;
  detail?: string;
};

export const StyledEditDetailWrapper = styled.div`
  max-width: 459px;
  padding-top: 16px;
  label {
    display: block;
    font-weight: 600;
  }
  input {
    border: none;
    background: ${props => props.theme.smartGray};
    border-radius: 4px;
    padding-left: 10px;
    margin-top: 16px;
    font-size: 16px;
    outline: ${props => props.theme.smartBlue};
    height: 30px;
    width: 100%;
    margin: 16px 0px;
  }
`;

const EditPhoneNumber = ({ title, detail }: Props) => {
  const mutation = useUpdatePhoneNumber();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, maxLength } = e.target;

    if (parseInt(value) || value === '') {
      setValue(value.slice(0, maxLength));
    }
  };

  const handleEditing = (editing: boolean) => {
    setValue(detail || '');
    setEditing(editing);
    mutation.reset();
  };

  const handleSave = () => {
    mutation.mutate(value);
    setEditing(false);
  };

  return (
    <StyledEditDetailWrapper>
      {mutation.isLoading ? (
        <ClipLoader color={'#2b5cff'} />
      ) : (
        <InputControls
          disabled={value.length !== 9}
          title={title}
          editing={editing}
          handleEditing={handleEditing}
          handleSave={handleSave}
        />
      )}
      {editing && !mutation.isLoading ? (
        <div style={{ position: 'relative' }}>
          <span style={{ position: 'absolute', bottom: '21px', left: '5px', opacity: '0.8' }}>
            0
          </span>
          <input
            style={{ paddingLeft: '15px' }}
            autoFocus
            placeholder={detail}
            value={value}
            onChange={handleChange}
            type='text'
            maxLength={9}
          />
        </div>
      ) : (
        !mutation.isLoading && (
          <p>
            {0}
            {detail}{' '}
            <SaveStatus mutation={mutation}>
              {mutation.isError
                ? `An error occurred: ${mutation?.error?.message}`
                : mutation.isSuccess
                ? 'Update successful'
                : ''}
            </SaveStatus>
          </p>
        )
      )}
    </StyledEditDetailWrapper>
  );
};

export default EditPhoneNumber;
