export interface ITheme {
  smartBlue: string;
  smartDark: string;
  smartYellow: string;
  smartGray: string;
  borderColor: string;
  inputBorder: string;
  smartGreen: string;
  smartRed: string;
}

const theme = {
  smartBlue: '#2b5cff',
  smartDark: '#36373c',
  smartYellow: '#fee857',
  smartGray: '#f7f7f7',
  borderColor: '#eaeaeb',
  inputBorder: '#dadada',
  smartGreen: '#34d399',
  smartRed: '#ef4444'
};

export default theme;
