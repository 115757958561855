import React from 'react';
import styled from 'styled-components/macro';

const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 16px;

  border-radius: 100px;
  margin-bottom: 10px;
`;

const StyledEmptyProgress = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.smartBlue};
  opacity: 0.2;
`;

const StyledProgress = styled.div<{ percentageByDays: string }>`
  position: absolute;
  z-index: 999;
  max-width: 100%;
  width: ${props => (props.percentageByDays ? props.percentageByDays : '0%')};
  height: 100%;
  border-radius: 100px;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.smartBlue};
`;

const ProgressContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    margin-top: 0;
  }
`;

type Props = {
  start: string | Date;
  end: string | Date;
};

const ProgressBar = ({ start, end }: Props) => {
  const progressPercentage = () => {
    const startDate = new Date(start).getTime(),
      endDate = new Date(end).getTime(),
      today = new Date().getTime();

    const startAndEnd = endDate - startDate;
    const startAndToday = today - startDate;
    return Math.round((startAndToday / startAndEnd) * 100) + '%';
  };

  return (
    <>
      <StyledProgressBar>
        <StyledEmptyProgress />
        <StyledProgress percentageByDays={progressPercentage()} />
      </StyledProgressBar>
      <ProgressContent>
        <p>{start.toString()}</p>
        <p>{end.toString()}</p>
      </ProgressContent>
    </>
  );
};

export default ProgressBar;
