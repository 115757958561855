import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const tabLinkStyle = css`
  display: flex;
  white-space: nowrap;
  padding: 0 0.5em;
  line-height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-weight: 600;
  font-size: 14px;
  color: unset;
  text-decoration: none;
  border-bottom: solid 5px #fff;
  border-top: solid 5px #fff;
  &:hover {
    color: #575757;
  }
  &.selected {
    border-bottom: solid 5px ${props => props.theme.smartBlue};
  }

  @media only screen and (min-width: 768px) {
    padding: 0 1em;
    font-size: 16px;
  }
`;

export const TabLink = styled(NavLink)`
  ${tabLinkStyle}
`;
export const ExternalTabLink = styled.a`
  ${tabLinkStyle}
`;

export const TabButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 190px;
  text-align: center;
  white-space: nowrap;
  padding: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background: none;
  color: unset;
  text-decoration: none;
  border-bottom: solid 5px #fff;
  border-top: solid 5px #fff;
  &:hover {
    color: #575757;
  }
  &.selected {
    border-bottom: solid 5px ${props => props.theme.smartBlue};
  }
`;
