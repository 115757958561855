export const convertDate = (date: string | Date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const dateToConvert = new Date(date);
  const day = dateToConvert.getDate();
  const year = dateToConvert.getFullYear();
  const month = dateToConvert.getMonth();
  return day + ' ' + months[month] + ' ' + year;
};
