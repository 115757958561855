import React, { useEffect, useState } from 'react';
import Card from '../Card/Card';
import ChromeDinoGame from 'react-chrome-dino';
import { PrimaryButton } from '../Buttons';
import styled, { keyframes } from 'styled-components/macro';
const blink = keyframes`
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`;
const StyledInstructions = styled.div`
  color: $text;
  font-weight: 6 00;
  animation: ${blink} 2s linear infinite;
`;
const DinosaurCard = () => {
  const [play, setPlay] = useState(false);
  const [instruct, setInstruct] = useState('Press space bar to play');
  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.code === 'Space') {
        setInstruct('');
      }
    });
  });
  return (
    <Card title='Play Dinosaur Run'>
      {play ? (
        <>
          <ChromeDinoGame />
          <StyledInstructions>{instruct}</StyledInstructions>
        </>
      ) : null}

      {play ? null : <PrimaryButton onClick={() => setPlay(!play)}>Play</PrimaryButton>}
    </Card>
  );
};

export default DinosaurCard;
