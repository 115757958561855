import { useState } from 'react';

export const usePriceState = (num: string) => {
  const numbersOnly = new RegExp('d+(.d{1,2})?');
  const [value, setValue] = useState(num);

  const setPrice = (value: string) => {
    if (!numbersOnly.test(value)) {
      setValue(value);
    }
  };

  return [value, setPrice] as const;
};
