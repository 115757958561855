import React from 'react';
import styled from 'styled-components/macro';
import mastercardLogo from '../../images/mastercard-logo.png';
import visaLogo from '../../images/visa-logo.png';
// import poliLogo from '../../images/poli-logo.png';
import bpayLogo from '../../images/bpay-logo.png';
import { LocationModel } from '../Helpers/Location';
const StyledRadioList = styled.div`
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
  .inputs {
    margin: 24px 0px 32px 0px;
  }

  .radio-item {
    margin: 0 10px;
    width: 90%;
    max-width: 405px;
    height: 118px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    border: solid 1px ${props => props.theme.borderColor};
    border-radius: 2px;
    background: white;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    }

    &.selected {
      border: solid 1px ${props => props.theme.smartBlue};
    }

    label {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      cursor: pointer;
      span {
        display: inline-block;
        margin-right: 14px;
      }
    }

    @media only screen and (min-width: 768px) {
      width: 189px;
    }
  }
  strong {
    margin: 8px 0px;
    font-size: 12px;
  }
`;

type Props = {
  handlePaymentOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  option: string;
  bpay?: boolean;
  fees?: boolean;
  getLocation? : LocationModel 
};
const PaymentOptions = ({
  handlePaymentOptionChange,
  option,
  fees = false,
  bpay = true,
  getLocation
}: Props) => {
  return (
    <StyledRadioList>
      <div className={`radio-item ${option === 'credit-card' ? 'selected' : ''}`}>
        <label htmlFor='credit-card'>
          <div>
            <img src={visaLogo} alt='visa' height='24px' />
            <img src={mastercardLogo} height='24px' alt='mastercard' />
          </div>
          {fees && <strong>{getLocation?.hidePayForEnergy ? '1.44%' : '1.82%'}   Transaction Fees</strong>}
          <input
            type='radio'
            id='credit-card'
            name='payment'
            value='credit-card'
            checked={option === 'credit-card'}
            onChange={handlePaymentOptionChange}
          />
        </label>
      </div>

      {bpay && (
        <div className={`radio-item ${option === 'bpay' ? 'selected' : ''}`}>
          <label htmlFor='bpay'>
            <img src={bpayLogo} height='24px' alt='Bpay logo' />
            {fees && <strong>Zero Transaction Fees</strong>}

            <input
              type='radio'
              id='bpay'
              name='payment'
              value='bpay'
              checked={option === 'bpay'}
              onChange={handlePaymentOptionChange}
            />
          </label>
        </div>
      )}
    </StyledRadioList>
  );
};

export default PaymentOptions;
