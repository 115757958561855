import axios, { AxiosResponse } from 'axios';
import { CustomerDetails } from '../../types/CustomerDetails';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import { ApiParamsAndHeaders } from '../../types/ApiParamsAndHeaders';

export const getCustomerDetails = async (apiParamsAndHeaders: ApiParamsAndHeaders) => {
  const { data } = await axios.post<any, AxiosResponse<CustomerDetails>>(
    `${process.env.REACT_APP_API}/Customer/Details`,
    { ...apiParamsAndHeaders.apiParams },
    { ...apiParamsAndHeaders.headers }
  );
  return data;
};

export default function useCustomerDetails() {
  const { apiParamsAndHeaders } = useContext(UserContext);

  return useQuery<CustomerDetails, Error>(
    ['customerDetails', apiParamsAndHeaders],
    () => getCustomerDetails(apiParamsAndHeaders),
    { enabled: !!apiParamsAndHeaders.headers.headers.Authorization }
  );
}
