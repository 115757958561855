export class StripePayment {
  constructor(
    public amount: number,
    public salesOrder: string,
    public currency: string,
    public name: string,
    public timeZone: string
  ) {}
}

export type StripeOrderDetails = {
  amountReceived: number;
  currency: string;
  customerId: string;
  email: string;
  maskedCard: string;
  paymentIntentId: string;
};
