import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`

	* {box-sizing: border-box;}
	
  body {
    margin: 0;
    padding: 0;
    background: #fbfbfb;
    font-family: 'Open Sans', sans-serif;
		color: #36373c;
  }

	h1 {
		font-size: 36px;
		font-family: 'Open Sans', sans-serif;
	}
	button {
		font-family: 'Open Sans', sans-serif;
	}

`;

export default GlobalStyle;
