import React from 'react';
import styled from 'styled-components/macro';
import Input from '../Input';
import ExpiryDateInputs from './ExpiryDateInputs';

type Props = {
  cardNumberError: boolean;
  cardNumber: string;
  handleCardNumber: (value: string) => void;
  cardName: string;
  handleCardName: (value: string) => void;
  month: string;
  handleMonthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  year: string;
  handleYearChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  cvn: string;
  handleCvn: (e: string) => void;
  cvnError: boolean;
  dateError: boolean;
};

const StyledCardDetails = styled.div<{ isError: boolean }>`
  display: block;
  flex-wrap: wrap;
  .errorMessage {
    flex-basis: 100%;
    flex-wrap: wrap;
    width: 100%;
    display: 'block';
    font-weight: 600;
    color: ${props => props.theme.smartRed};
    margin-top: '8px';
    margin-bottom: '16px';
  }
  @media only screen and (min-width: 913px) {
    display: flex;
  }
  .input-cols {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
    input {
      border: solid 1px ${props => (props.isError ? props.theme.smartRed : props.theme.inputBorder)};
      background: #fff;
      border-radius: 4px;
      padding-left: 10px;
      font-size: 16px;
      height: 46px;
      width: 118px;
      margin-top: 16px;

      margin-right: 17px;
    }
  }
`;

const CardDetailsInputs = ({
  cardNumberError,
  cardNumber,
  handleCardNumber,
  cardName,
  handleCardName,
  month,
  handleMonthChange,
  year,
  handleYearChange,
  cvn,
  handleCvn,
  cvnError,
  dateError
}: Props) => {
  return (
    <>
      <h3 style={{ textAlign: 'center', margin: '32px auto' }}>Pay with debit or credit card</h3>
      <div className='inputs'>
        <Input
          isError={cardNumberError}
          label='Card number'
          type='text'
          placeholder=''
          autoComplete='cc-number'
          errorMessage='Must be a valid card'
          value={cardNumber}
          onChange={handleCardNumber}
        />
        <Input
          isError={false}
          label='Name on the card'
          type='text'
          autoComplete='cc-name'
          placeholder=''
          value={cardName}
          onChange={handleCardName}
        />
        <ExpiryDateInputs
          month={month}
          handleMonthChange={handleMonthChange}
          year={year}
          handleYearChange={handleYearChange}
          dateError={dateError}
        />
        <StyledCardDetails isError={cvnError}>
          <label>
            <span style={{ display: 'block', fontWeight: 600, marginTop: '16px' }}>CVV</span>
            <div className='input-cols'>
              <input
                value={cvn}
                minLength={3}
                maxLength={4}
                type='text'
                onChange={e => handleCvn(e.target.value)}
              />
            </div>
          </label>
          {cvnError && <span className='errorMessage'>CVN invalid</span>}
        </StyledCardDetails>
      </div>
    </>
  );
};

export default CardDetailsInputs;
