import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Card from './Card';

type Props = {
  count?: number;
};
const SkeletonCard = ({ count = 5 }: Props) => {
  return (
    <Card>
      <h3>{<Skeleton />}</h3>
      <p>
        <Skeleton count={count} />
      </p>
    </Card>
  );
};

export default SkeletonCard;
