import axios from "axios";
import { useEffect, useState } from "react";

export const useGetLocation = () => {
    const [countryName, setCountryName] = useState('')
    const [countryCode, setCountryCode] = useState('')

    const getData = async()=>{
        const res = await axios.get('https://geolocation-db.com/json/')
        setCountryCode(res.data.country_code)
        console.log(res.data.country_code);
        setCountryName(res.data.country_name)
    }
    
    useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    }, [])

    return {
        countryCode,
        countryName
    }
}