import styled from 'styled-components/macro';

import BillingList from '../components/Billing/BillingList';
import TotalPaymentCard from '../components/Billing/TotalPaymentCard';
import Layout from '../components/Layout/Layout';
import AccountBalanceCard from '../components/Overview/AccountBalanceCard';

const StyledBillingHeading = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 46px;
  margin: 24px 0px;

  select {
    display: block;
    flex-grow: 0;
    border: none;
    font-size: 16px;
    color: ${props => props.theme.smartDark};
    font-weight: 600;
    padding-right: 1em;
    margin-bottom: 5px;
  }
`;

const MobileOrDesktop = styled.div`
  .mobile {
    width: 100%;
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  .desktop {
    width: 100%;
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const BillingScreen = () => {
  return (
    <Layout pageTitle={'My Bills'} addressHeader mainPadding='0px'>
      <h1>Bills</h1>
      <MobileOrDesktop>
        <div className='mobile'>
          <AccountBalanceCard />
        </div>
        <div className='desktop'>
          <TotalPaymentCard />
        </div>
      </MobileOrDesktop>
      <StyledBillingHeading>
        <select>
          <option>2021</option>
          {/* <option>2021</option> */}
        </select>
      </StyledBillingHeading>
      <BillingList />
    </Layout>
  );
};

export default BillingScreen;
