import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Redirect, RouteProps } from 'react-router';
import { ThemeProvider } from 'styled-components/macro';
import GlobalStyle from './globalStyles';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import BillingScreen from './screens/BillingScreen';
import DetailsScreen from './screens/DetailsScreen';
import OverviewScreen from './screens/OverviewScreen';
import SignInScreen from './screens/SignInScreen';

import { auth } from './service/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import UserProvider from './context/UserContext';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';

import LoadingScreen from './screens/LoadingScreen';
import UnauthorisedScreen from './screens/UnauthorisedScreen';
import PaymentScreen from './screens/PaymentScreen';
import PaymentSuccessScreen from './screens/PaymentSuccessScreen';
import StripeSuccessScreen from './screens/StripeSuccessScreen';
import theme from './theme';
import ReactGA from 'react-ga4';
import { Helmet, HelmetProvider } from 'react-helmet-async';

ReactGA.initialize('G-2Q23JELHCE');
ReactGA.send('pageview');
const queryClient = new QueryClient();

const AuthRoute = ({ ...props }: RouteProps) => {
  const [user, loading] = useAuthState(auth);
  if (loading) return <LoadingScreen />;
  if (!user) return <Redirect to='/' />;
  return <Route {...props} />;
};

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <title>Customer Portal</title>
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <UserProvider>
              <Switch>
                <Route path='/' component={SignInScreen} exact />
                <Route path='/forgot-password' component={ForgotPasswordScreen} />
                <AuthRoute
                  path='/reset-password'
                  render={() => <ForgotPasswordScreen loggedIn={true} />}
                />
                <Route
                  path='/reset-confirmation'
                  render={() => <ResetPasswordScreen title={'Create a new password'} />}
                />
                <Route path='/welcome' render={() => <ForgotPasswordScreen welcome={true} />} />
                <AuthRoute path='/overview' component={OverviewScreen} />
                <AuthRoute path='/unauthorised' component={UnauthorisedScreen} />
                <AuthRoute path='/details' component={DetailsScreen} />
                <AuthRoute path='/bills' component={BillingScreen} />
                <Route path='/pay' component={PaymentScreen} exact />
                <Route path='/checkout' component={StripeSuccessScreen} />
                <Route path='/pay/success' component={PaymentSuccessScreen} exact />
              </Switch>
            </UserProvider>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
