import React from 'react';
import feelGoodBg from '../../images/feel-good-bg.png';
import styled from 'styled-components/macro';
import DetailItem from '../DetailItem';
import useRatePlan from '../../hooks/api/useRatePlan';
import { ClipLoader } from 'react-spinners';

const StyledPlanDetailsWrapper = styled.div`
  background: white;
  color: ${props => props.theme.smartDark};
  width: 100%;
  max-height: auto;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.borderColor};
  margin-top: 20px;
  .background {
    padding: 24px 20px 30px 24px;
    height: 166px;
    background-image: url(${feelGoodBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    h2 {
      font-size: 20px;
      margin-top: 0px;
    }
  }
  .content {
    padding: 24px 20px 0px 24px;
  }

  .gst-text {
    font-size: 12px;
    color: ${props => props.theme.smartDark};
    margin-left: 24px;
    font-weight: bold;
  }
  @media only screen and (min-width: 768px) {
    min-height: 300px;
    max-width: 296px;
    margin-left: 1em;
  }
`;

const PlanDetails = () => {
  const { data, isLoading, isError } = useRatePlan();
  if (isLoading)
    return (
      <div style={{ margin: '10px auto', display: 'block', textAlign: 'center' }}>
        <ClipLoader color={'#2b5cff'} />
      </div>
    );

  if (isError)
    return (
      <div style={{ margin: '10px auto', display: 'block', maxWidth: '250px' }}>
        <h2 style={{ fontSize: ' 20px' }}>Information unavailable</h2>
        <p style={{ fontSize: '14px' }}>Oops, something went wrong. Please refresh the page.</p>
      </div>
    );

  return (
    <StyledPlanDetailsWrapper>
      <div className='background'>
        <h2>Feel Good Plan</h2>
      </div>
      <div className='content'>
        {data?.tariffLists.map(detail => (
          <DetailItem
            key={detail.chargeDescription}
            title={detail.chargeDescription}
            detail={`${detail.rate} ${detail.unit}`}
          />
        ))}
      </div>
      <p className='gst-text'>Rates are inclusive of GST</p>
    </StyledPlanDetailsWrapper>
  );
};

export default PlanDetails;
