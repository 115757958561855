import Skeleton from 'react-loading-skeleton';
import useCustomerDetails from '../../../hooks/api/useCustomerDetails';

import { Line } from '../../Line';
import EditPassword from './EditPassword';

import EditPhoneNumber from './EditPhoneNumber';
import ReadDetail from '../ReadDetail';
import styled from 'styled-components';

const StyledDetailsContainer = styled.div`
  width: 100%;
`;
const DetailsForm = () => {
  const { data, isLoading, isError, error, isIdle } = useCustomerDetails();
  if (isError) return <p>{error?.message}</p>;

  return (
    <StyledDetailsContainer>
      <h3>Personal Details</h3>
      {isLoading || isIdle ? (
        <Skeleton count={3} style={{ marginTop: '10px' }} />
      ) : (
        <ReadDetail title='Full name' detail={`${data?.firstName} ${data?.lastName}`} />
      )}
      <Line />
      {isLoading || isIdle ? (
        <Skeleton count={3} style={{ marginTop: '10px' }} />
      ) : (
        <EditPhoneNumber title='Phone number' detail={data?.phoneNumber} />
      )}
      <Line />
      <h3>Login Details</h3>
      {isLoading || isIdle ? (
        <Skeleton count={3} style={{ marginTop: '10px' }} />
      ) : (
        <ReadDetail title='Email' detail={data?.email} />
      )}
      <Line />
      {isLoading || isIdle ? (
        <Skeleton count={2} style={{ marginTop: '10px' }} />
      ) : (
        <EditPassword />
      )}
      {/* <Line /> */}
      {/* <h3>Payment method</h3>
      <EditPayment /> */}
    </StyledDetailsContainer>
  );
};

export default DetailsForm;
