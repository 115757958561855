import React, { useRef } from 'react';
import styled from 'styled-components/macro';

type Props = {
  handleMonthChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleYearChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  month: string;
  year: string;

  dateError: boolean;
};

const StyledCardDetails = styled.div<{ isError: boolean }>`
  display: block;
  flex-wrap: wrap;
  .errorMessage {
    flex-basis: 100%;
    flex-wrap: wrap;
    width: 100%;
    display: 'block';
    font-weight: 600;
    color: ${props => props.theme.smartRed};
    margin-top: '8px';
    margin-bottom: '16px';
  }
  @media only screen and (min-width: 913px) {
    display: flex;
  }
  .input-cols {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
    input {
      border: solid 1px ${props => (props.isError ? props.theme.smartRed : props.theme.inputBorder)};
      background: #fff;
      border-radius: 4px;
      padding-left: 10px;
      font-size: 16px;
      height: 46px;
      width: 118px;
      margin-top: 16px;

      margin-right: 17px;
    }
  }
`;

const ExpiryDateInputs = ({
  month,
  year,

  handleMonthChange,
  handleYearChange,

  dateError
}: Props) => {
  const monthInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);

  const handleMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleMonthChange(e);
    if (e.target.value.length === 2 && null !== monthInputRef) {
      yearInputRef.current?.focus();
    }
  };
  const handleYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleYearChange(e);
    if (e.target.value === '' && null !== yearInputRef) {
      monthInputRef.current?.focus();
    }
  };
  return (
    <StyledCardDetails isError={dateError}>
      <label className='expiry-date'>
        <span style={{ display: 'block', fontWeight: 600, marginTop: '16px' }}>Expiry</span>
        <div className='input-cols'>
          <input
            placeholder='MM'
            maxLength={2}
            pattern='\d*'
            ref={monthInputRef}
            type='text'
            autoComplete='cc-exp-month'
            value={month}
            onChange={handleMonth}
          />
          <input
            placeholder='YY'
            type='text'
            pattern='\d*'
            ref={yearInputRef}
            value={year}
            autoComplete='cc-exp-year'
            maxLength={2}
            onChange={handleYear}
          />
        </div>
      </label>
      {dateError && <span className='errorMessage'>invalid expiry date</span>}
    </StyledCardDetails>
  );
};

export default ExpiryDateInputs;
