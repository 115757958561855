import React from 'react';
import styled from 'styled-components/macro';

const StyledSecurePaymentsLogos = styled.div`
  margin: 32px;
  border-top: solid 1px ${props => props.theme.borderColor};
  .logos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    img {
      display: block;
      margin-right: 24px;
      margin-bottom: 16px;
    }
  }
`;

type Props = {
  img1?: string;
  img2?: string;
};
const SecurePaymentsLogos = ({ img1, img2 }: Props) => {
  return (
    <StyledSecurePaymentsLogos>
      <h4>Secure payments by</h4>
      <div className='logos'>
        {img1 && <img src={img1} alt='secure payment' height='35px' />}
        {img2 && <img src={img2} alt='secure payment' height='35px' />}
      </div>
    </StyledSecurePaymentsLogos>
  );
};

export default SecurePaymentsLogos;
