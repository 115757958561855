import React, { useState } from 'react';

import styled from 'styled-components/macro';
import { ExternalPrimaryButtonLink, PrimaryButtonLink, SecondaryButtonLink } from '../Buttons';
import Chevron from '../Icons/Chevron';

type StyleProps = {
  src?: string;
  isError: boolean;
};

const StyledCard = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 2em;
  color: ${props => props.theme.smartDark};
  margin: 10px auto;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${props => props.theme.borderColor};
  .card-content {
    margin-top: 30px;
    h3 {
      font-size: 20px;
    }
    a {
      color: ${props => props.theme.smartBlue};
    }
  }
  .card-content-dropdown {
    background: white;
  }

  h2 {
    font-size: 20px;
    margin: 0;
  }

  h3 {
    font-size: 24px;
  }

  .card-img {
    position: relative;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 2em;
    margin: -2em -2em -2em -2em;
    display: block;
    max-width: 600px;
    height: 226px;
    background-image: url(${props => (props.src ? props.src : '')});
    background-size: cover;
    background-position: center;
  }
  @media only screen and (min-width: 1025px) {
    margin: 0px 11px 22px 11px;
    width: 402px;
  }
`;

const StyledDropDownContent = styled.div<{ toggle: boolean }>`
  display: ${props => (!props.toggle ? 'none' : 'block')};
  padding-top: 50px;
  margin-bottom: 0px;
`;

type Props = {
  title?: string;
  imgSrc?: string;
  index?: number;
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  linkUrl?: string;
  button?: 'primary' | 'secondary' | 'none';
  buttonText?: string;
  isDropDown?: boolean;
  isError?: boolean;
  initialToggle?: boolean;
  externalLink?: boolean;
};

const Card: React.FC<Props> = ({
  children,
  title,
  imgSrc = undefined,
  textAlign = 'center',
  button = 'none',
  buttonText = '',
  linkUrl = '/',
  isDropDown = false,
  isError = false,
  initialToggle = false,
  externalLink = false
}) => {
  const [toggle, setToggle] = useState(initialToggle);

  return (
    <StyledCard src={imgSrc} isError={isError}>
      {/* If imgSrc and title are true, show title in image div */}
      {imgSrc && (
        <div className='card-img' onClick={() => setToggle(!toggle)}>
          {imgSrc && title && <h2>{title}</h2>}
          {isDropDown ? (
            <div>
              <Chevron
                style={{
                  position: 'absolute',
                  dislay: 'block',
                  width: '24px',
                  height: '24px',
                  textAlign: 'center',
                  bottom: '10px',
                  left: '50%',
                  transition: 'all 0.3s',
                  transform: `translate(-50%, 0) rotate(${toggle ? 0 + 'deg' : 180 + 'deg'})`
                }}
              />
            </div>
          ) : null}
        </div>
      )}
      {!imgSrc ? <h2>{title}</h2> : null}

      {isDropDown ? (
        <div
          className='card-content-dropdown'
          style={{ maxHeight: !toggle ? 0 : 500, transition: 'all 0.3s' }}
        >
          <StyledDropDownContent toggle={toggle}>{children}</StyledDropDownContent>
        </div>
      ) : (
        <div className='card-content' style={{ textAlign: textAlign }}>
          {children}
        </div>
      )}

      {button === 'primary' ? (
        externalLink ? (
          <ExternalPrimaryButtonLink href={linkUrl}>{buttonText}</ExternalPrimaryButtonLink>
        ) : (
          <PrimaryButtonLink to={linkUrl}>{buttonText}</PrimaryButtonLink>
        )
      ) : button === 'secondary' ? (
        <SecondaryButtonLink to={linkUrl}>{buttonText}</SecondaryButtonLink>
      ) : null}
    </StyledCard>
  );
};

export default Card;
