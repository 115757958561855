import React from 'react';
import Loader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import useCustomerDetails from '../../hooks/api/useCustomerDetails';
import useStatementDetails from '../../hooks/api/useStatementDetails';
import { isOverDue } from '../../utils/isOverdue';
import { overduePrice } from '../../utils/overduePrice';
import { PrimaryButtonLink } from '../Buttons';
import { StyledStatus } from './Status';

const StyledTotalPaymentCard = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeaeb;
  background-color: #fff;
  display: flex;
  padding: 26px;
  justify-content: space-between;
  align-items: center;

  .total {
    font-weight: 900;
    span {
      font-size: 14px;
    }
    p {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 24px;
      display: flex;
      align-items: center;
    }
  }
`;
const TotalPaymentCard = () => {
  const {
    data: statementData,
    isError: isStatementError,
    isLoading: isStatementLoading,
    isIdle: isStatementIdle
  } = useStatementDetails();

  const {
    data: customerData,
    isError: isCustomerError,
    isLoading: isCustomerLoading,
    isIdle: isCustomerIdle
  } = useCustomerDetails();

  if (isCustomerLoading || isStatementLoading || isCustomerIdle || isStatementIdle) {
    return (
      <StyledTotalPaymentCard>
        <Loader color={'#2b5cff'} />
      </StyledTotalPaymentCard>
    );
  }

  if (isStatementError || isCustomerError || !statementData || !customerData) {
    return null;
  }
  const currentBalance =
    customerData!.currentBalance < 0
      ? customerData!.currentBalance.toFixed(2).replace('-', '') + ' cr \u2665	'
      : customerData!.currentBalance.toFixed(2);
  return (
    <StyledTotalPaymentCard>
      <div className='total'>
        <span>Total Outstanding</span>
        <p style={{ color: customerData!.currentBalance <= 0 ? '#34d399' : '' }}>
          ${currentBalance}
        </p>
      </div>
      <div className='total'>
        <span>Total Overdue</span>
        <p>
          {overduePrice(statementData!.currentCharges, customerData!.currentBalance)}
          <StyledStatus
            overdue={isOverDue(statementData!.currentCharges, customerData!.currentBalance)}
          >
            {isOverDue(statementData!.currentCharges, customerData!.currentBalance)
              ? 'Overdue'
              : 'nothing overdue'}
          </StyledStatus>
        </p>
      </div>
      <PrimaryButtonLink to='/pay' style={{ width: '170px', margin: '0px' }}>
        Make a payment
      </PrimaryButtonLink>
    </StyledTotalPaymentCard>
  );
};

export default TotalPaymentCard;
