import styled from 'styled-components/macro';
import { CustomerDetails } from '../../types/CustomerDetails';
import selectArrows from '../../images/select-arrows.svg';

import useCustomerDetails from '../../hooks/api/useCustomerDetails';
import { useContext } from 'react';
import { AvailableCustomerNumber, UserContext } from '../../context/UserContext';

const StyledWrapper = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  select {
    width: 100%;
    padding: 17px 25px;
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px ${props => props.theme.borderColor};
    margin: 22px 0px;
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    option {
      padding: 0;
      margin: 0;
      font-size: 14px;
    }
  }
  &:after {
    position: absolute;
    right: 17px;
    top: 40px;
    z-index: 999;
    content: '';
    display: block;
    width: 10px;
    height: 16px;
    background-size: cover;
    background-color: white;
    background-image: url(${selectArrows});
  }
  @media only screen and (min-width: 1025px) {
    margin: 22px 11px 0px 11px;
    width: 100%;
    max-width: 100%;
  }
`;

const AddressSelect = () => {
  const { loggedInUser, handleSwitchCustomerNumber } = useContext(UserContext);

  return (
    <StyledWrapper>
      <select
        value={loggedInUser.customerNumber}
        onChange={e => handleSwitchCustomerNumber(e.target.value)}
      >
        {loggedInUser.availableCustomerNumbers.map((customer: AvailableCustomerNumber) => (
          <option key={customer.customerNumber} value={customer.customerNumber}>
            {customer.displayValue}
          </option>
        ))}
      </select>
    </StyledWrapper>
  );
};

export default AddressSelect;
