import React from 'react';
import styled from 'styled-components/macro';

const StyledDownload = styled.button`
  background: none;
  border: none;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

type Props = {
  download: () => void;
};

const DownloadLink = ({ download }: Props) => {
  return (
    <StyledDownload onClick={download}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'>
        <g fill='none' fillRule='evenodd'>
          <path d='M0 0h24v24H0z'></path>
          <path
            fill='#2B5CFF'
            fillRule='nonzero'
            d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4l-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z'
          ></path>
        </g>
      </svg>
    </StyledDownload>
  );
};

export default DownloadLink;
