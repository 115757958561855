import axios from 'axios';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import styled from 'styled-components/macro';
import ErrorMessage from '../components/ErrorMessage';
import { CalculateLocation } from '../components/Helpers/Location';
import Header from '../components/Layout/Header';
import { TabButton } from '../components/Navigation/Tabs';
import EnergyPaymentForm from '../components/Payment/EnergyPaymentForm';

import SolarPaymentForm from '../components/Payment/SolarPaymentForm';
import { useGetLocation } from '../hooks/useGetLocation';

const StyledContainer = styled.div`
  max-width: 615px;
  width: 90%;
  margin: 32px auto 0px auto;
`;

const StyledHeader = styled.div`
  margin-top: 54px;
  text-align: center;

  border-bottom: 1px solid ${props => props.theme.borderColor};
  h1 {
    padding-top: 54px;
    @media (max-width: 1024px) {
      padding-top: unset;
    }
  }
  .tabs {
    display: flex;
    justify-content: center;
  }
`;

const PaymentScreen = () => {
  const history = useHistory();
  const isSolar = history.location.hash === '#solar';

  const [tab, setTab] = useState<'energy' | 'solar'>(isSolar ? 'solar' : 'energy');
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {countryCode, countryName} = useGetLocation()
  const getLocation = CalculateLocation(countryCode);

  return (
    <div style={{ backgroundColor: '#fff', minHeight: '100vh', width: '100%' }}>
      <Header getLocation={getLocation}/>
      <StyledHeader>
        <h1>Make a payment</h1>
        <p>Pay for energy or solar with one of the payment options below.</p>
        {/* {!user ? ( */}
        <div className='tabs'>
          {!getLocation.hidePayForEnergy ? <TabButton
            className={tab === 'energy' ? 'selected' : ''}
            onClick={() => setTab('energy')}
          >
            Pay for energy
          </TabButton> : ""
          }
          <TabButton className={getLocation.hidePayForEnergy ? 'selected' :  tab === 'solar' ? 'selected' : ''} onClick={() => setTab('solar')}>
            Pay for solar
          </TabButton>
        </div>
        {/* ) : null} */}
      </StyledHeader>
      {submitError && (
        <ErrorMessage
          resetError={() => setSubmitError(false)}
          message={`Oops, an error occurred: Please ensure all details are correct.`}
        />
      )}
      <StyledContainer>
        {
          getLocation.hidePayForEnergy ? <SolarPaymentForm getLocation={getLocation}/> :
          tab === 'solar' ? (
            <SolarPaymentForm getLocation={getLocation}/>
          ) : (
            <EnergyPaymentForm setSubmitError={setSubmitError} setErrorMessage={setErrorMessage} />
          )
        }
      </StyledContainer>
    </div>
  );
};

export default PaymentScreen;
