import React from 'react';
import GrayDetails from '../components/Details/GrayDetails';
import Layout from '../components/Layout/Layout';
import DetailsForm from '../components/Details/DetailsForm/DetailsForm';
import styled from 'styled-components/macro';
import PlanDetails from '../components/Details/PlanDetails';

const StyledDetailsScreen = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const DetailsScreen = () => {
  return (
    <Layout pageTitle={'My Details'} addressHeader={true} mainPadding='0px'>
      <h1>My Details</h1>
      <StyledDetailsScreen>
        <DetailsForm />
        <div>
          <GrayDetails />
          <PlanDetails />
        </div>
      </StyledDetailsScreen>
    </Layout>
  );
};

export default DetailsScreen;
