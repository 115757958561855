import styled from 'styled-components/macro';

export const StyledEditDetailWrapper = styled.div`
  max-width: 459px;
  padding-top: 16px;
  label {
    display: block;
    font-weight: 600;
  }
  input {
    border: none;
    background: ${props => props.theme.smartGray};
    border-radius: 4px;
    padding-left: 10px;
    margin-top: 16px;
    font-size: 16px;
    outline: ${props => props.theme.smartBlue};
    height: 30px;
    width: 100%;
    margin: 16px 0px;
  }
`;
