import styled from 'styled-components/macro';

export const StyledControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  label {
    font-weight: 600;
  }
  button,
  a {
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Open Sans';
    color: ${props => props.theme.smartBlue};
    font-weight: 700;
    text-decoration: none;
  }
`;
