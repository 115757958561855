// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: 'smart-energy-dev.firebaseapp.com',
  projectId: 'smart-energy-dev',
  storageBucket: 'smart-energy-dev.appspot.com',
  messagingSenderId: '356229636256',
  appId: '1:356229636256:web:bee01230bd558dea9369c5'
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
const db = app.firestore();

const logout = async () => {
  try {
    await auth.signOut();
  } catch (err) {
    console.debug(err);
  }
};

export { logout, db };
