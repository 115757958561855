import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

const primaryButtonStyles = css`
  display: block;
  text-align: center;
  border-radius: 4px;
  background-color: ${props => props.theme.smartBlue};
  height: 48px;
  line-height: 48px;
  width: 100%;
  color: #fff !important;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s;
  margin-top: auto;
  &:hover {
    opacity: 0.9;
  }
`;

const secondaryButtonStyles = css`
  display: block;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  height: 48px;
  line-height: 48px;
  width: 100%;
  color: ${props => props.theme.smartDark} !important;
  font-weight: 600;
  text-decoration: none;
  border: solid 1px ${props => props.theme.smartBlue};
  transition: all 0.2s;
  margin-top: auto;
  &:hover {
    background: ${props => props.theme.smartGray};
    color: #fff;
  }
`;

export const PrimaryButtonLink = styled(Link)`
  ${primaryButtonStyles}
`;

export const ExternalPrimaryButtonLink = styled.a`
  ${primaryButtonStyles}
`;

export const PrimaryButton = styled.button`
  ${primaryButtonStyles}
  border: none;
  cursor: pointer;
  font-size: 16px;
  &:disabled {
    opacity: 0.4 !important;
    cursor: not-allowed;
  }
`;

export const SecondaryButtonLink = styled(Link)`
  ${secondaryButtonStyles}
`;
export const SecondaryButtonAnchor = styled.a`
  ${secondaryButtonStyles}
`;

export const SecondaryButton = styled.div`
  ${secondaryButtonStyles}
`;
